//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getPostMessage} from "~/vold-core/functions/helpers"
import {GlobalMixin} from "@/mixin/GlobalMixin"
import {mapState} from "vuex"
import LoadingMixin from "@/mixin/animations/LoadingMixin"

import HeaderComponent from "@/components/global/HeaderComponent/HeaderComponent"
import LoadingComponent from "@/components/global/LoadingComponent/LoadingComponent"
import SearchComponent from "@/components/global/SearchComponent/SearchComponent"
import EnquiryComponent from "@/components/partials/EnquiryComponent/EnquiryComponent"
import MenuComponent from "@/components/global/MenuComponent/MenuComponent"
import LightboxComponent from "@/components/global/LightboxComponent/LightboxComponent"

import LanguageSelector from "~/components/global/LanguageSelector/LanguageSelector"
import {triggerResize} from "~/plugins/helper"
import PopupComponent from '~/components/global/PopupComponent/PopupComponent.vue'

export default {
    components: {
        LanguageSelector,
        EnquiryComponent,
        LoadingComponent,
        SearchComponent,
        HeaderComponent,
        MenuComponent,
        LightboxComponent,
        PopupComponent
    },
    mixins: [GlobalMixin, LoadingMixin],
    computed: mapState({
        smoothScroll: state => state.SmoothScroll.smoothScroll,
        loadingShown: state => state.Animation.isLoadingShown,
    }),
    watch: {
        smoothScroll(val) {
            let background = document.querySelector('.main_background')
            let header = document.querySelector('#headerBg')

            if (background != null) {
                background.style.visibility = val == null ? 'hidden' : ''
            }

            if (header != null) {
                header.style.visibility = val == null ? 'hidden' : ''
            }
        },
    },
    mounted() {
        getPostMessage(event => {
            this.$root.$emit('PM', event)
        })

        this.$Lazyload.$on('loaded', () => {
            triggerResize();
        })
    }
}
