//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TweenMax, TimelineMax, Expo} from 'gsap'
import {mapState} from 'vuex'
import {CrudHelper} from "~/vold-core/functions/Crudhelper"
import {GlobalMixin} from "~/mixin/GlobalMixin"

export default {
    name: "SearchComponent",
    mixins: [GlobalMixin],
    data() {
        return {
            search: '',
            searchTimeout: null,
            open: false,
            items: [],
            resultOpen: false,
            next: null
        }
    },
    computed: {
        ...mapState({
            isSearchOpen: state => state.Animation.isSearchOpen
        }),
        closeText() {
            const text = this.getStaticText('command - close')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        filteredItems() {
            let regex = new RegExp(`.*${this.search}.*`)

            let result = this.items.filter(item => {
                return regex.exec(this.__(item.title)) !== null
            })

            if (result != null) {
                return result
            }

            return []
        }
    },
    watch: {
        open(val) {
            const tl = new TimelineMax

            if (val) {
                this.$nextTick(() => {
                    tl.fromTo(this.$refs.search, .5, {
                        clipPath: 'inset(0 0 100% 0)'
                    }, {
                        clipPath: 'inset(0 0 40% 0)',
                        ease: Expo.easeInOut
                    })

                    tl.fromTo(this.$refs.input, .5, {
                        clipPath: 'inset(0 100% 0 0)'
                    }, {
                        clipPath: 'inset(0 0% 0 0)',
                        ease: Expo.easeInOut
                    })

                    TweenMax.fromTo(this.$refs.backdrop, .5, {alpha: 0},
                        {
                            alpha: 1,
                            ease: Expo.easeInOut
                        })
                })
            }
        },
        isSearchOpen(val) {
            if (val) {
                this.search = ''
                this.searchTimeout = null
                this.items = []
                this.resultOpen = false

                this.open = true
            } else {
                TweenMax.to(this.$refs.search, .5, {
                    clipPath: 'inset(0 0 100% 0)',
                    ease: Expo.easeIn,
                    onComplete: () => {
                        if (this.searchTimeout != null) {
                            clearTimeout(this.searchTimeout)
                        }

                        this.search = ''
                        this.searchTimeout = null
                        this.items = []
                        this.resultOpen = false
                        this.open = false
                    }
                })

                TweenMax.to(this.$refs.backdrop, .5, {
                    alpha: 0,
                    ease: Expo.easeIn
                })
            }
        },
        resultOpen(val) {
            if (val) {
                TweenMax.to(this.$refs.search, .5, {
                    clipPath: 'inset(0 0 0% 0)',
                    ease: Expo.easeInOut
                })
            }
        },
        search(val) {
            if (val === '') return

            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout)
                this.searchTimeout = null
            }

            this.searchTimeout = setTimeout(async () => {
                this.resultOpen = true

                let response = await CrudHelper.GET('post', {
                    where: JSON.stringify({
                        $or: [
                            {type: 'village'},
                            {type: 'centrale'},
                            {type: 'blog'},
                            {type: 'event'},
                        ],
                    })
                })

                this.items = response.results.results
                this.searchTimeout = null
            }, 500)
        }
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            if (this.isSearchOpen) {
                this.close()
                setTimeout(next, 500)
            } else {
                next()
            }
        })
    },
    methods: {
        navigate(link) {
            this.$router.push(link)
        },
        close() {
            this.$store.commit('setSearchOpen', false)
        },
        beforeEnter(el) {
            TweenMax.set(el, {
                clipPath: 'inset(0 100% 0 0)',
                scale: .875,
                opacity: 0
            })
        },
        enter(el, done) {
            let index = el.dataset.index

            TweenMax.to(el, .5, {
                delay: index * .1,
                clipPath: 'inset(0 0% 0 0)',
                scale: 1,
                opacity: 1,
                onComplete: done
            })
        }
    }
}
