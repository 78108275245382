let apiKey = '5f645ed79dae8c540e681d5b'


export default () => {
    if (process.env.NODE_ENV === 'production' && process.env.ON_STAGING !== 'yes') {
        return production
    } else {
        return develop
    }
}

const develop = {
    domain: 'https://api.vold.io',
    api_key: apiKey,
    // api_base: 'http://104.248.150.125/v1/api',
    api_base: 'https://eu.chain-hotel-api.fleava.com/v1/api',
    /**
     * @deprecated
     * user clientOrigin instead
     */
    client_origin: 'http://localhost:6063',

    clientOrigin: 'http://localhost:6063',

    asset_url: 'https://eu.chain-hotel-api.fleava.com/v1/api/pic/lab',
    /**
     * @deprecated
     * use apiUrl instead
     * @version 1.0.1
     */
    api_url: `https://eu.chain-hotel-api.fleava.com/v1/api/${apiKey}`,
    clientId: apiKey,
    aws_url: 'https://vold-chain-hotel-eu.s3.eu-central-1.amazonaws.com'
}


const production = {
    domain: 'http://104.248.150.125:5000',
    api_key: apiKey,
    // api_base: 'http://104.248.150.125/v1/api',
    api_base: 'https://eu.chain-hotel-api.fleava.com/v1/api',
    /**
     * @deprecated
     * user clientOrigin instead
     */
    client_origin: 'https://eu.chain-hotel.fleava.com',

    clientOrigin: 'https://eu.chain-hotel.fleava.com',

    asset_url: 'https://eu.chain-hotel-api.fleava.com/v1/api/pic/lab',
    /**
     * @deprecated
     * use apiUrl instead
     * @version 1.0.1
     */
    api_url: `https://eu.chain-hotel-api.fleava.com/v1/api/${apiKey}`,
    clientId: apiKey,
    aws_url: 'https://vold-chain-hotel-eu.s3.eu-central-1.amazonaws.com'
}
