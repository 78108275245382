import { render, staticRenderFns } from "./EnquiryComponent.vue?vue&type=template&id=0975bf5d&scoped=true&xmlns%3Acontact=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./EnquiryComponent.vue?vue&type=script&lang=js&"
export * from "./EnquiryComponent.vue?vue&type=script&lang=js&"
import style0 from "./EnquiryComponent.scss?vue&type=style&index=0&id=0975bf5d&lang=scss&scoped=true&"
import style1 from "./ContactFormComponent.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0975bf5d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactFormComponent: require('/var/www/frontend-lustica-nuxt/components/tpl/forms/ContactFormComponent/ContactFormComponent.vue').default})
