import Vue from "vue"
import {createComponent, isEmptyObject} from "../functions/helpers"

Vue.directive('image-editor', {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        const {$root} = context
        let {state, model, disable, pos} = binding.value
        const userCanEdit = context.$store.getters.getCurrentUserCanEditContent
        if (userCanEdit && !disable) {


            if (isEmptyObject(model)) {
                model = Object.assign({}, model, {filename: null})


            }

            const asyncComponent = () => ({
                component: import('../component-editor/ButtonMedia/ButtonMedia')
            })


            createComponent(asyncComponent().component, comp => {
                const buttonId = comp.buttonId
                el.classList.add('has-button-media-editor')
                el.appendChild(comp.$el)
                if (pos) comp.$el.classList.add(pos)

                $root.$on('PM', event => {
                    if (!event) return
                    const {message} = event
                    if (event.key === 'SELECTED_FILE') {
                        if (message.to === buttonId) {
                            if (state) state(message.file)

                            if (model) model = message.file
                        }
                    }
                })
            })
        }
    }
})
