import {CrudHelper} from "@/vold-core/functions/Crudhelper"

export const EventStore = {
    namespaced: true,
    state: {
        onRequest: false,
        event: {},
        page: 0
    },

    mutations: {
        setRequest(state, payload) {
            state.onRequest = payload;
        },
        setEvent(state, payload) {
            state.event = payload;
        },
        setIsLast(state, payload) {
            state.isLast = payload;
        },
        next(state) {
            if (!state.event.isLast)  {
                state.page++;
            }
        },
        previous(state) {
            if (state.page > 0) {
                state.page--;
            }
        }
    },

    actions: {
        requestEvent({ state, commit }) {
            if (state.onRequest || state.event.isLast)
                return new Promise(() => state.events);

            commit('setRequest', true);

            return CrudHelper.GET('post', {
                where: {type: 'event'},
                skip: 0,
                size: 6 * (state.page + 1),
            }).then(({data}) => {
                commit('setEvent', data);
                commit('setRequest', false);
            });
        }
    }
}
