import ENV from "../environment/ENV"

export const GlobalMixin = {
    data: () => {
        return {
            currentLang: "en",
            isClient: process.client,
        }
    },

    props: {
        /**
         * Because of component if placed on random order position
         * and component it self has different margin, padding size and also style in every position
         * this props can be help to manage those problem
         * data should be send from page builder
         */
        classStyle: {
            type: Object,
            default: null
        },

        options: {
            /**
             * Available options
             * @return Object
             */
            type: Object,
            default: () => {
                return {}
            }
        },

        dataModel: {
            type: String | Array | Object | Boolean,
            default: null
        },
        dataName:{
            type: String | Array | Object | Boolean,
            default: null
        },

        /**
         * Every component that have title, should use this props
         */
        title: {
            /**
             * Plain string or object with language
             */
            type: String | Object,
            default: null
        },

        /**
         * Every component that have description, should use this props
         */
        description: {
            /**
             * Plain string or object with language
             */
            type: String | Object,
            default: null
        },

        /**
         * Every component that have image featured, should use this props
         */
        featured: {
            type: String | Object,
            default: null
        },

        /**
         * Every component that have image slider, should use this props
         */
        images: {
            type: Array,
            default: null
        },

        /**
         * Every component that have image slider, should use this props
         */
        dataSlider: {
            type: Array,
            default: null
        },

        /**
         * If component has button
         */
        buttonLinkLabel: {
            type: String,
            default: "Book Now"
        },

        disabledButtonReadMore: {
            type: Boolean,
            default: false
        },

        dataLocation: {
            type: Array,
            default: null
        },

        type: {
            type: String,
            default: 'normal'
        }
    },

    computed: {
        getSocials() {
            return this.getWebConfig.data.social_media
        },
        getDataModel() {
            if (this.dataModel) return this.dataModel
            else return this.$props
        },

        getTotalSlider() {
            let totalSlider = 1
            if (
                this.getDataModel.dataSlider &&
                this.getDataModel.dataSlider.length
            ) {
                totalSlider = this.getDataModel.dataSlider.length
            }
            return totalSlider
        },

        getPageData() {
            return this.$store.getters.getCurrentPageData
        },

        getWebConfig() {
            const pagedata = this.$store.getters.getCurrentPageData
            if (pagedata) return pagedata.webconfig
        },

        getEvents() {
            const page = this.$store.getters.getCurrentPageData
            if (page && page.posts) {
                return page.posts.filter(post => post.type === "event")
            }
        },

        getPostDetail() {
            const postDetail = this.$store.getters.getCurrentPostDetail
            if (postDetail) return postDetail
            return null
        },

        getListingPost() {
            const page = this.$store.getters.getCurrentPageData
            if (page && page.posts) {
                return page.posts
            }

            return []
        },

        getPageComponents() {
            const page = this.$store.getters.getCurrentPageData

            if (page && page.components) {
                return page.components
            }

            return null
        },

        getOptions() {
            return this.options
        },

        getDataName(){
            return this.dataName
        },

        getClassStyle() {
            return this.getDataModel.classStyle
        },

        isSlider() {
            const slider = this.renderModelData("dataSlider")
            if (slider) {
                return slider.length > 1
            }
            return null
        },

        renderOgMeta() {
            const getPageData = this.getPageData

            const content = getPageData.single

            const ogMeta = []
            if (content.meta) {
                for (let key in content.meta) {
                    if (Object.prototype.hasOwnProperty.call(content.meta, key)) {
                        ogMeta.push(
                            {
                                name: key,
                                hid: key,
                                content:
                                    key == "image"
                                        ? this.renderImage(content.meta[key])
                                        : this.renderMultipleLangValue(
                                            content.meta[key]
                                        )
                            },
                            {
                                property: `og:${key}`,
                                hid: `og:${key}`,
                                content:
                                    key == "image"
                                        ? this.renderImage(content.meta[key])
                                        : this.renderMultipleLangValue(
                                            content.meta[key]
                                        )
                            },
                            {
                                name: `twitter:${key}`,
                                hid: `twitter:${key}`,
                                content:
                                    key == "image"
                                        ? this.renderImage(content.meta[key])
                                        : this.renderMultipleLangValue(
                                            content.meta[key]
                                        )
                            }
                        )
                    }
                }
            }

            return ogMeta
        },

        renderMetaPage() {
            const getContent = this.getPageData


            const content = getContent.single

            // const content = this.getPostDetail
            //

            let metaTitle = 'Lustica Bay'
            //
            if (content) {
                metaTitle =
                    content.meta &&
                    content.meta.title &&
                    this.renderMultipleLangValue(content.meta.title) !== ""
                        ? this.renderMultipleLangValue(content.meta.title)
                        : this.renderMultipleLangValue(content.title) // Default if meta title not provided

                if (!metaTitle && content.components) {
                    metaTitle = 'Lustica Bay • ' + this.renderMultipleLangValue(
                        content.components.title
                    )
                }

                const OG = this.renderOgMeta


                return {
                    htmlAttrs: {
                        lang: this.getCurrentLanguage
                    },
                    title: metaTitle || ('Lustica Bay • ' + this.renderMultipleLangValue(content.title)),
                    meta: [...OG]
                }
            }

            return {
                htmlAttrs: {
                    lang: this.getCurrentLanguage
                },
                title: "Home",
                meta: []
            }
        },

        isCurrentUserCanEdit() {
            return this.$store.getters.getCurrentUserCanEditContent
        },

        getCurrentLanguage() {
            return this.$store.getters.getCurrentLanguage
        },

        getPageId() {
            return this.getPageData &&
            this.getPageData.single &&
            this.getPageData.single.pageId
                ? this.getPageData.single.pageId
                : ""
        },
    },

    methods: {
        getWebsiteNavigationByPosition(position) {
            const websiteData = this.getPageData

            let navigations = []
            if (websiteData && websiteData.navigation_collection) {
                if (position) {
                    const selectedNavigation = websiteData.navigation_collection.find(
                        nav => nav.position === position
                    )
                    if (selectedNavigation) {
                        if (selectedNavigation.page) {
                            navigations = [...selectedNavigation.page]
                        }

                        if (selectedNavigation.custom) {
                            navigations = [
                                ...navigations,
                                ...selectedNavigation.custom
                            ]
                        }
                    }
                }
            }

            return navigations
        },

        /**
         *
         * @param event
         * @param position {string} // left or right
         * @return {boolean}
         */
        windowClickedPosition(event, position) {
            const centerWindow = window.innerWidth / 2
            if (event.x > centerWindow && position === "right") return true
            else if (event.x < centerWindow && position === "left") return true

            return false
        },

        renderImage(imageKey, size, isFleava) {
            let assetUrl = ENV().asset_url

            let placeHolderImage = "/images/no-image.jpg"

            if (typeof imageKey === "string") {
                if (imageKey.indexOf("http") === -1) {
                    return `${ENV().asset_url}${imageKey}`
                }

                return imageKey
            }

            if (!imageKey) return placeHolderImage

            /**
             * Data from chain hotel
             */
            if (imageKey.filename && imageKey.path) {
                const folderPath = `${imageKey.path}/${
                    imageKey.filename[size || "raw"]
                }`

                if (imageKey.is_aws && !isFleava) {
                    assetUrl = ENV().aws_url
                }


                const fullPath = `${assetUrl}/${folderPath}`

                return `${fullPath}`
            }

            let imagePath = placeHolderImage

            // if (imageKey.full_path && !size) imagePath = imageKey.full_path;
            // else {
            let path = imageKey.path
            if (size && imageKey.thumbnails)
                path =
                    imageKey.thumbnails[size] || imageKey.thumbnails["thumb"]
            if (path) imagePath = `${this.assetsUrl}${path}`
            // }

            return imagePath
        },

        renderFile(file) {
            let assetUrl = ENV().asset_url
            if (file && file.filename && file.path) {
                const folderPath = `${file.path}/${
                    file.filename["raw"]
                }`
                assetUrl = file.aws_file_url


                const fullPath = `${assetUrl}/${folderPath}`

                return `${fullPath}`
            } else {
                return '#'
            }
        },

        renderImageFeatured(item) {
            if (Object.prototype.hasOwnProperty.call(item, "featured")) {
                return item.featured
            }

            return item
        },

        detectmob() {
            if (!process.browser) return
            return !!(
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
            )
        },

        disableScroll() {
            if (process.browser) {
                document.body.classList.add("no-scroll")
            }
        },

        enableScroll() {
            if (process.browser) {
                document.body.classList.remove("no-scroll")
            }
        },

        renderMultipleLangValue(data, enterToBreak) {
            const getLanguage = this.$store.getters.getCurrentLanguage
            let content = ""
            /**
             * Prevent to error when language not provided
             */
            if (data && typeof data === "object") {
                if (getLanguage in data) {
                    content = data[getLanguage]
                } else {
                    content = data["en"]
                }
            } else {
                content = data
            }

            if (content) {
                content = content.trim()
            }
            /**
             * Replace line break to <br>
             */
            if (content && enterToBreak) {
                content = content.replace(/\u00a0/g, '').replace(/(?:\r\n|\r|\n)/g, "<br>")
            }

            return content
        },

        /***
         * Translate object into active language
         * @param data object to translate
         * @param enterToBreak replace enter with html break
         * @returns string
         * @private
         */
        __(data, enterToBreak) {
            const getLanguage = this.$store.getters.getCurrentLanguage
            let content = ""
            /**
             * Prevent to error when language not provided
             */
            if (data && typeof data === "object") {
                if (getLanguage in data) {
                    content = data[getLanguage]
                } else {
                    content = data["en"]
                }
            } else {
                content = data
            }

            if (content) {
                content = content.trim()
            }
            /**
             * Replace line break to <br>
             */
            if (content && enterToBreak) {
                content = content.replace(/\u00a0/g, '').replace(/(?:\r\n|\r|\n)/g, "<br>")
            }

            return content
        },

        renderSlug(item, prefix) {
            let currentSlug = "/"
            const getLanguage = this.$store.getters.getCurrentLanguage


            if (item.type) {
                currentSlug = `${this.renderPostRoutePath(item)}`
            } else {
                const slug = this.renderMultipleLangValue(item.slug)
                if (slug === "/") {
                    currentSlug = ""
                } else {
                    currentSlug = `${this.renderMultipleLangValue(item.slug)}`
                }
            }


            if (prefix) currentSlug = `${prefix}/${currentSlug}`

            return `/${getLanguage}/${currentSlug}`
        },

        renderLink(link) {
            const getLanguage = this.$store.getters.getCurrentLanguage
            if (!link && getLanguage === 'en') return '/'
            if (!link) return `/${getLanguage}`

            if (
                link.startsWith("http") ||
                link.endsWith(".pdf") ||
                link.startsWith("mailto") ||
                link.startsWith("tel")
            ) {
                return link
            } else {
                if (!link.startsWith('/')) {
                    link = '/' + link
                }

                return link
            }
        },

        renderPostLink(type, slug, singleSlug = false) {
            const getLanguage = this.$store.getters.getCurrentLanguage
            if (!slug) return `/${getLanguage}`

            let currentSlug

            if (singleSlug) {
                currentSlug = slug
            } else {
                currentSlug = slug[getLanguage]
            }

            if (!currentSlug) return

            if (
                currentSlug.indexOf("http") !== -1 ||
                currentSlug.includes(".pdf") ||
                currentSlug.includes("mailto")
            ) {
                return currentSlug
            } else {
                return `/${getLanguage}/list/${type}/${currentSlug}`
            }
        },

        renderPostRoutePath(item) {
            return `${item.type}/${this.renderMultipleLangValue(item.slug)}`
        },

        renderModelData(model, isMultipleLanguage = true, enterToBreak) {
            let data = null

            if (this.dataModel) data = this.dataModel[model]
            else data = this[model] // get from props

            if (data && !Array.isArray(data) && isMultipleLanguage)
                return this.renderMultipleLangValue(data, enterToBreak)
            else return data
        },

        renderComponentStyle() {
            const style = this.getClassStyle

            if (style) {
                for (let key in style) {
                    if (Object.prototype.hasOwnProperty.call(style, key)) {
                        try {
                            const styleText = key.replace(
                                /([A-Z])/g,
                                g => `-${g[0].toLowerCase()}`
                            )
                            if (this.$el)
                                this.$el.style.setProperty(
                                    styleText,
                                    style[key],
                                    "important"
                                )
                        } catch (e) {
                            //TODO: catch exception
                        }
                    }
                }
            }
        },

        getWebsiteConfig() {
            return this.getPageData.webconfig.data
        },

        getPostDataByType(type) {
            return this.getPageData.posts.filter(post => post.type === type)
        },
        getStaticText(id) {
            const getLanguage = this.$store.getters.getCurrentLanguage
            let result = this.getPageData.static_texts.filter(text => {
                return text.title.en === id || text.title.mne === id
            })


            const getContentLanguage = (content, language) => {
                if (typeof content !== 'object') return
               
                return content[language] ? content[language] : content['en'];
            }

            if (Array.isArray(result) && result.length > 0) {
                return getContentLanguage(result[0].description, getLanguage)
            }

            return {}
        },
        escapedStaticText(id) {
            const text = this.getStaticText(id)

            if (typeof text !== 'string') {
                throw new Error(`text is not a string id: ${id}`)
            }

            if (text != null && typeof text === 'string') {
                return text.replace(/<.*?>/g, '')
            }

            return ''
        },

        getFormBuilder(id) {
            let forms = this.getPageData.form_builders.filter(form => form.formId === id)

            if (Array.isArray(forms) && forms.length > 0) {
                return forms[0];
            }

            return {}
        },

        strip(tags) {
            return tags.replace(/<(.*?)>/g, '');
        },
        nosymbol(value){

            const regex =new RegExp(/[^\p{L}\s]+/gmu)
            const message = {
                en:'invalid name format',
                mne:'неважећи формат имена',
                ru:'неверный формат имени'
            }
            if(regex.test(value)) return message[this.getCurrentLanguage]
            return true
            
        }
    },
}
