export const PageContentStore = {
    state: {

        /**
         * We store current active page detail here, called post to make it global name
         */
        currentPostDetail: null,

        /**
         * Store current post/page components listing
         * Includes languages
         */
        currentPostDetailComponent: {},


        /**
         * Return components blocks for current language
         * @return {Array}
         */
        currentPostDetailComponentsBlock: [],

        hotels: [],

        /**
         * Storing page content so api not accessing every time
         */
        pageCache: {},
        parentPostDetail: null
    },

    mutations: {


        /**
         *
         * @param state
         * @param payload {Object}
         * {
         *     id: {String},
         *     content: {Object}
         * }
         */
        setPageCache(state, payload) {
            if (typeof payload !== 'object') return
            state.pageCache[payload.id] = payload.content
        },

        setParentPostDetail: (state, payload) => {
            state.parentPostDetail = payload
        },


        setHotelListing: (state, payload) => {
            state.hotels = payload
        },


        setCurrentPostComponentsBlock: (state, payload) => {
            state.currentPostDetailComponentsBlock = payload
        },


        /**
         * Setter your current post/page detail
         * @param state
         * @param payload
         */
        setCurrentPostDetail: (state, payload) => {
            state.currentPostDetail = payload
            /**
             * Set component listing
             */

            if (state.currentPostDetail) {
               if(state.currentPostDetail.components){
                state.currentPostDetailComponent = state.currentPostDetail.components
               }else{
                state.currentPostDetailComponent = []
               }
            }
        },

        /**
         * Setter you current page/post components listing
         * @param state
         * @param payload {Object}, multiple language component
         */
        setCurrentPostDetailComponents: (state, payload) => {
            state.currentPostDetailComponent = payload
        }
    },

    getters: {

        /**
         * Getter current post/page detail data
         * @param state
         * @return {null|*}
         */
        getCurrentPostDetail: state => state.currentPostDetail,

        /**
         * Getters current page/post component listing
         * use this to getting components listing or you can use same result by using $store.getters.getCurrentPostDetail.components
         * @param state
         * @return {PageContentStore.state.currentPostDetailComponent|{}|Object}
         */
        getCurrentPostDetailComponent: state => state.currentPostDetailComponent,


        getCurrentPostComponentsBlock: state => state.currentPostDetailComponentsBlock,
        getParentPostDetail: state => state.parentPostDetail,
        getHotelListing: state => state.hotels
    }
}
