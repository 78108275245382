export const SmoothScrollStore = {
    state: {
        smoothScroll: null,
        loadingCompleted: false
    },

    mutations: {
        setSmoothScroll(state, payload) {
            state.smoothScroll = payload
        },
        setLoadingCompleted(state, payload) {
            state.loadingCompleted = payload
        }
    },

    getters: {
        getSmoothScroll(state) {
            return state.smoothScroll
        }
    }
}
