export const PopupGalleryStore = {
    state: {
        isPopupImgOpen: false,
        popupImgURL: ''
    },

    mutations: {
        setPopupImgOpen(state, payload) {
            state.isPopupImgOpen = payload
        },

        setPopupImgURL(state, payload) {
            state.popupImgURL = payload
        }
    }
}
