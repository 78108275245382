//
//
//
//

export default {
    name: "CustomLoading",
    data() {
        return {
            loading: false
        }
    },
    methods: {
        start() {
            this.loading = true;
            this.$store.commit('setPageChanged', true);
        },
        finish() {
            this.loading = false;
            this.$store.commit('setPageChanged', false);
        }
    }
}
