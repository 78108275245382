//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContactFormComponent from "@/components/tpl/forms/ContactFormComponent/ContactFormComponent"
import {mapState, mapActions} from 'vuex'
import {TweenMax, Expo} from 'gsap'
import {GlobalMixin} from "~/mixin/GlobalMixin";

export default {
    name: "EnquiryComponent",
    components: {ContactFormComponent},
    mixins: [GlobalMixin],
    data() {
        return {
            open: false,
            loading: false,
        }
    },
    computed: {
        ...mapState({
            'show': state => state.Enquiry.show
        }),
        expressText() {
            return this.getStaticText('form - express')
        },
        close() {
            const text = this.getStaticText('command - close')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    watch: {
        show(val) {
            if (val) {
                this.open = true

                this.$nextTick(() => {
                    TweenMax.fromTo(this.$refs.enquiry.querySelector('.enquiry__container'),
                        .5, {
                            clipPath: 'inset(0 100% 0 0)'
                        }, {
                            clipPath: 'inset(0 0% 0 0)',
                            ease: Expo.easeOut,
                        })

                    TweenMax.fromTo(this.$refs.enquiry.querySelector('.backdrop'),
                        .5, {
                            opacity: 0
                        }, {
                            opacity: 1
                        })

                    TweenMax.staggerFromTo(this.$refs.enquiry.querySelectorAll('.form-group'),
                        .5, {
                            xPercent: -5,
                            opacity: 0
                        }, {
                            xPercent: 0,
                            opacity: 1
                        }, .05)
                })
            } else {
                TweenMax.to(this.$refs.enquiry.querySelector('.backdrop'),
                    .5, {
                        opacity: 0
                    })

                TweenMax.to(this.$refs.enquiry.querySelector('.enquiry__container'),
                    .5, {
                        clipPath: 'inset(0 100% 0 0)',
                        ease: Expo.easeInOut,
                        onComplete: () => {
                            this.open = false
                        }
                    })
            }
        }
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            if (this.show) {
                this.hide()
                setTimeout(next, 500)
            } else {
                next()
            }
        })
    },
    methods: {
        ...mapActions({
            'hide': 'Enquiry/hide'
        }),
        sek() {
            this.loading = true
        },
        wes() {
            this.loading = false;
        }
    }
}
