export const LightboxStore = {
    namespaced: true,
    state: {
        enlarge: false,
        image: null
    },

    mutations: {
        setLightbox(state, payload) {
            state.enlarge = payload
        },
        setImage(state, payload) {
            state.image = payload
        }
    },
    actions: {
        show({commit}, payload) {
            commit('setLightbox', true)
            commit('setImage', payload)
        },
        hide({commit}) {
            commit('setLightbox', false)
        }
    }
}
