import { render, staticRenderFns } from "./error.vue?vue&type=template&id=27428072&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "../components/global/Page404Component/Page404Component.scss?vue&type=style&index=0&id=27428072&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27428072",
  null
  
)

export default component.exports