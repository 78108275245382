import Vue from 'vue'
import {createComponent} from '../functions/helpers'
import _ from 'lodash'
import {v1 as uuidv4} from 'uuid'

Vue.directive('image-gallery-editor', {

    bind: (el, binding, vnode) => {
        let {model, onUpdated, thumbKey, disable} = binding.value

        if (model) {
            model.map(item => {
                if (!item.id) item.id = uuidv4()
            })
        }


        if (onUpdated) onUpdated(model)

        const newModel = _.cloneDeep(model)
        const {context} = vnode
        const {$root} = context
        const userCanEdit = context.$store.getters.getCurrentUserCanEditContent

        if (userCanEdit && !disable) {

            if (!thumbKey) thumbKey = 'banner'

            const asyncComponent = () => ({
                component: import('../component-editor/GalleryEditor/GalleryEditor')
            })

            const button = document.createElement('div')
            const label = document.createElement('div')
            const buttonEdit = document.createElement('div')
            const buttonContainer = document.createElement('div')

            label.classList.add('label')
            label.innerText = 'Gallery'
            buttonContainer.classList.add('vold__button-gallery-editor-container')
            button.classList.add('button-gallery')
            button.innerHTML = '<svg viewBox="0 0 31.444 31.444"><path d="M1.119 16.841a1.118 1.118 0 01-1.111-1.127c0-.619.492-1.111 1.111-1.111h13.475V1.127A1.133 1.133 0 0115.722 0c.619 0 1.111.508 1.111 1.127v13.476h13.475c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H16.833v13.476c0 .619-.492 1.127-1.111 1.127a1.131 1.131 0 01-1.127-1.127V16.841H1.119z" fill="#1e201d"/></svg>'
            buttonEdit.classList.add('button-gallery', 'button-gallery-editor')
            buttonEdit.innerHTML = '<svg height="512pt" viewBox="0 -18 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="M512 80v106c0 11.047-8.953 20-20 20s-20-8.953-20-20V80c0-22.055-17.945-40-40-40H80c-22.055 0-40 17.945-40 40v280c0 22.055 17.945 40 40 40h140c11.047 0 20 8.953 20 20s-8.953 20-20 20H80c-44.113 0-80-35.887-80-80V80C0 35.887 35.887 0 80 0h352c44.113 0 80 35.887 80 80zM83.895 325.934c8.8 6.675 21.343 4.957 28.023-3.844l28.406-37.434 52.774 66.746A19.993 19.993 0 00208.785 359h.024a20.007 20.007 0 0015.695-7.633l153.86-195.488 31.206 37.844c7.024 8.523 19.63 9.734 28.153 2.707 8.523-7.028 9.734-19.633 2.707-28.153l-47-57A19.958 19.958 0 00377.773 104a20.022 20.022 0 00-15.492 7.629L208.75 306.707l-53.063-67.113c-3.835-4.852-9.683-7.621-15.886-7.594a20.007 20.007 0 00-15.735 7.91l-44.011 58c-6.68 8.797-4.957 21.344 3.84 28.024zM80 132c0-33.086 26.914-60 60-60s60 26.914 60 60-26.914 60-60 60-60-26.914-60-60zm40 0c0 11.027 8.973 20 20 20s20-8.973 20-20-8.973-20-20-20-20 8.973-20 20zm374.426 121.574c23.394 23.395 23.394 61.457 0 84.852l-109.57 109.367a19.962 19.962 0 01-8.794 5.117l-80.722 22.363a19.99 19.99 0 01-19.656-5.309 19.993 19.993 0 01-4.82-19.776l23.913-78.727a19.966 19.966 0 015.008-8.344L409.59 253.555c23.379-23.375 61.441-23.375 84.836.02zm-60.457 88.68l-28.29-28.29-74.09 73.927-11.882 39.129 40.61-11.25zm32.172-60.395c-7.797-7.8-20.485-7.8-28.282 0l-3.863 3.852 28.285 28.285 3.875-3.867c7.785-7.785 7.785-20.473-.015-28.27zm0 0"/></svg>'
            el.classList.add('vold__has-button-gallery')


            buttonContainer.appendChild(label)
            buttonContainer.appendChild(button)
            buttonContainer.appendChild(buttonEdit)
            el.appendChild(buttonContainer)


            button.addEventListener('click', () => {
                const newItem = _.cloneDeep(model[model.length - 1])
                /**
                 * Added new items
                 */
                model.push(newItem)
                newModel.push(newItem)

                setTimeout(() => {
                    if (onUpdated) onUpdated(model)
                }, 100)


            })


            buttonEdit.addEventListener('click', () => {


                createComponent(asyncComponent().component, comp => {
                    comp.keyMap = thumbKey
                    $root.$el.appendChild(comp.$el)

                    comp.$on('closeEditor', event => {
                        comp.$el.parentNode.removeChild(comp.$el)
                    })

                    comp.$on('successDelete', index => {
                        model.splice(index, 1)
                        newModel.splice(index, 1)

                        setTimeout(() => {
                            if (onUpdated) onUpdated(model)
                        }, 100)
                    })
                }, {
                    model: _.cloneDeep(model)
                })
            })
        }

    }

})
