import {LanguageMixin} from "./LanguageMixin";

export const NavigationMixin = {
    mixins: [LanguageMixin],

    data: function() {
        return {
            navigations:  []
        }
    },

    created() {
        this.navigations = this.getPageData.navigation_collection;
    },

    methods: {
        getNavigationByID(id) {
            if(this.navigations) {
                return this.navigations.find(navigation => navigation.position === id)
            } else {
                return null
            }
        },

        getNavigationTitle(navigation) {
            if(navigation.customTitle) {
                return this.getContentLanguage(navigation.customTitle, this.lang)
            } else {
                if(navigation.data) return this.getContentLanguage(navigation.data.title, this.lang)
            }
        },

        getNavigationSlug(navigation, lang) {
            if (lang == null) {
                lang = this.lang
            }

            if (lang === 'en'
                && navigation.customUrl != null
                && this.getContentLanguage(navigation.customUrl) === '/') {
                return '/';
            }

            if(navigation.data && navigation.data.path !== '') {
                return '/' + lang + this.getContentLanguage(navigation.data.path, this.lang)
            } else if(navigation.customUrl){
                const link = this.getContentLanguage(navigation.customUrl, this.lang)

                if (
                    link.startsWith("http") ||
                    link.endsWith(".pdf") ||
                    link.startsWith("mailto") ||
                    link.startsWith("tel")
                ) {
                    return link
                }

                return '/' + lang + link
            } else {
                return '/'
            }
        },

        getChildNavigationSlug(navigation) {
            return '/'  + this.currentLang + '/' + this.getContentLanguage(navigation.data.slug, this.lang)
        }
    }
}
