export const AnimationStore = {
    state: {
        isDetailLoaded: false,
        isImagesLoaded: false,
        isMenuOpen: false,
        inInquiryOpen: false,
        isSearchOpen: false,
        isRegisterOpen: false,
        isLoadingDone: false,
        isVideoOpen: false,
        isLanguageSelectorOpen: false,
        videoUrl: 'NNIaOngoSUQ',
        isPageChanged: false,
        isFlipbookOpen: false,
        flipbookUrl: null,
        isLoadingShown: false,
        invertMenu: false,
        isCookieBoxOpen: false,
    },

    mutations: {
        setCookieBoxOpen(state, payload) {
            state.isCookieBoxOpen = payload
        },
        setImagesLoaded(state, payload) {
            state.isImagesLoaded = payload
        },
        setDetailLoaded(state, payload) {
            state.isDetailLoaded = payload
        },
        setInvertMenu(state, payload) {
            state.invertMenu = payload
        },

        setMenuOpen(state, payload) {
            state.isMenuOpen = payload
        },

        setInquiryOpen(state, payload) {
            state.inInquiryOpen = payload
        },

        setSearchOpen(state, payload) {
            state.isSearchOpen = payload
        },

        setRegisterOpen(state, payload) {
            state.isRegisterOpen = payload
        },

        setLoadingDone(state, payload) {
            state.isLoadingDone = payload
        },

        videoUrl(state, payload) {
            state.videoUrl = payload
        },

        setVideoOpen(state, payload) {
            state.isVideoOpen = payload
        },

        setPageChanged(state, payload) {
            state.isPageChanged = payload
        },

        setFlipbookOpen(state, payload) {
            state.isFlipbookOpen = payload
        },

        setFlipbookUrl(state, payload) {
            state.flipbookUrl = payload
        },

        setLoadingShown(state, payload) {
            state.isLoadingShown = payload
        },

        setLanguageSelector(state, payload) {
            state.isLanguageSelectorOpen = payload
        },
    },

    actions: {
        blackMenu({commit}) {
            commit('setInvertMenu', true)
        },
        whiteMenu({commit}) {
            commit('setInvertMenu', false)
        },
        openMenu({commit}) {
            commit('setMenuOpen', true)
        },
        hideMenu({commit}) {
            commit('setMenuOpen', false)
        },
        showLanguageSelector({commit}) {
            commit('setLanguageSelector', true)
        },
        hideLanguageSelector({commit}) {
            commit('setLanguageSelector', false)
        }
    },

    getters: {
        getIsVideoOpen(state) {
            return state.isVideoOpen
        },

        getVideoUrl(state) {
            return state.videoUrl
        },

        getPageChanged(state) {
            return state.isPageChanged
        },

        getFlipbookOpen(state) {
            return state.isFlipbookOpen
        },

        getFlipbookUrl(state) {
            return state.flipbookUrl
        }
    }
}
