export const CursorStore = {
    state: {
        cursorAnimation: null
    },

    mutations: {
        setCursorAnimation(state, payload) {
            state.cursorAnimation = payload
        }
    },

    getters: {
        getCursorAnimation(state) {
            return state.cursorAnimation
        }
    }
}
