import {BaseClass} from "../classes/BaseClass"
import {PostClassMiddleware} from "../classes/PostClassMiddleware"
import {CrudHelper} from "../vold-core/functions/Crudhelper"
import {isEmptyObject} from "../vold-core/functions/helpers"

class RenderPageData extends BaseClass {
    constructor(ctx) {
        super(ctx)
        this.websiteData = {
            single: null
        }
        const {store} = this.context
        const websiteData = store.getters.getCurrentPageData
        if (websiteData)
            this.websiteData = {...store.getters.getCurrentPageData}
        this.posts = new PostClassMiddleware(ctx, this)

        if (!isEmptyObject(this.context.route.params)) {
            if (this.context.route.params.id == null) {
                if (this.context.route.params.language === 'hr') {
                    return this.context.redirect('/mne')
                }

                if (!['en', 'mne', 'ru'].includes(this.context.route.params.language)) {
                    return this.context.redirect('/')
                }
            }
        }
    }

    async init() {
        const {store} = this.context

        await this.getWebConfig()

        if (!this.websiteData.posts) {
            await this.posts.init()
        }
        await this.getSingle()
        await this.getNavigation()
        await this.getStaticText()
        await this.getFormBuilder()

        this.getLanguageCode()

        const websiteData = {
            ...this.posts.websiteData
        }

        this.websiteData = Object.assign({}, this.websiteData, websiteData)

        /**
         * Storing data into vuex
         * @this.websiteData = {}
         */
        store.commit("setCurrentPageData", this.websiteData)
    }

    async getWebConfig() {
        const {webconfig} = this.websiteData
        if (!webconfig) {
            const {data} = await CrudHelper.GET("webconfig")
            this.websiteData.webconfig = data
        }
    }

    async getNavigation() {
        const {data: {results}} = await CrudHelper.GET("navigation")
        this.websiteData.navigation_collection = results
    }

    async getStaticText() {
        const {
            data: {results}
        } = await CrudHelper.GET('static')

        this.websiteData.static_texts = results
    }

    async getFormBuilder() {
        const {form_builders} = this.websiteData

        if (!form_builders) {
            const {
                data: {results}
            } = await CrudHelper.GET('form-builder')

            this.websiteData.form_builders = results
        }
    }

    /**
     * Get page single
     * @returns {Promise<void>}
     */
    async getSingle() {
        super.getSingle()
        const {
            route: {name},
            params: {type, slug, featured, detail},
            store,
        } = this.context

        if (name === "index" || (name === 'language-type-featured-slug' && slug == null)) {
            const pageId = this.websiteData.webconfig.default_homepage
            const single = await this.getDetailPageByID(pageId)
            this.websiteData.single = single
            store.commit("setCurrentPostDetail", single)
        } else if (name === "language-slug" && slug) {
            const single = await this.getDetailPage(slug)
            this.websiteData.single = single
            store.commit("setCurrentPostDetail", single)
        } else if (name === "language-slug-detail") {
            const single = await this.getDetailPage(detail)
            this.websiteData.single = single
            store.commit("setCurrentPostDetail", single)
        } else if (type === 'list' && featured && slug) {
            const single = await this.getDetailPost(slug)
            this.websiteData.single = single
            store.commit("setCurrentPostDetail", single)
        }
    }

    getLanguageCode() {
        const {store, route} = this.context

        if (!isEmptyObject(route.params)) {
            if (route.params.language || route.params.slug) {
                store.commit(
                    "setSelectedWebsiteLanguage",
                    route.params.language || route.params.slug
                )
                store.commit(
                    "setCurrentLanguage",
                    route.params.language || route.params.slug
                )
            } else {
                store.commit(
                    "setSelectedWebsiteLanguage", "en"
                )
                store.commit(
                    "setCurrentLanguage", "en"
                )
            }
        } else {
            store.commit(
                "setSelectedWebsiteLanguage", "en"
            )
            store.commit(
                "setCurrentLanguage", "en"
            )
        }
    }
}

export default function (context) {
    const renderPage = new RenderPageData(context)

    return renderPage.init()
}
