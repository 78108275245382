//
//
//
//
//
//

import {mapState} from 'vuex';
import {Expo, TweenMax} from "gsap"

export default {
    name: "LoadingComponent",
    computed: mapState({
        loadingCompleted: state => state.SmoothScroll.loadingCompleted,
    }),
    watch: {
        loadingCompleted(val) {
            if(val){
                TweenMax.fromTo(this.$el, 1, {
                    alpha: 1,
                }, {
                
                    alpha: 0,
                    ease: Expo.easeInOut,
                    onComplete: () => {
                        this.$store.commit('setLoadingShown', true);
                    }
                })
            }
        },
    },
}
