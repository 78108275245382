import {PageStore} from './PageStore'
import Vue from 'vue'
import Vuex from 'vuex'

import {WebsiteContentStore} from "../vold-core/Store/WebsiteContentStore"
import {PageContentStore} from "../vold-core/Store/PageContentStore"
import {EditorStore} from "../vold-core/Store/Editor"
import {SmoothScrollStore} from "~/store/animations/SmoothScrollStore";
import {CursorStore} from "~/store/animations/CursorStore";
import {WeatherStore} from "@/store/animations/WeatherStore"
import {EventStore} from "@/store/posts/EventStore"
import {AnimationStore} from "@/store/animations/AnimationStore"
import {LightboxStore} from "@/store/animations/LightboxStore"
import {EnquiryStore} from "@/store/animations/EnquiryStore"

Vue.use(Vuex)
const createStore = () => {
    return new Vuex.Store({
        state: {
            counter: 0,
            currentLanguage: 'en'
        },

        modules: {
            Page: PageStore,
            websiteContent: WebsiteContentStore,
            pageData: PageContentStore,
            editor: EditorStore,
            SmoothScroll: SmoothScrollStore,
            Cursor: CursorStore,
            Weather: WeatherStore,
            Event: EventStore,
            Animation: AnimationStore,
            Lightbox: LightboxStore,
            Enquiry: EnquiryStore
        },


        mutations: {

            setCurrentLanguage: (state, payload) => {
                state.currentLanguage = payload
            }
        },

        getters: {
            getCurrentLanguage: state => state.currentLanguage,
        }
    })
}

export default createStore
