import ENV from '../../environment/ENV'
import axios from 'axios'

export class CrudHelper {

    static apiUrl() {
        let apiUrl = ENV().apiUrl
        if (!apiUrl) {
            apiUrl = ENV().api_url
            return `${apiUrl}`
        }

        return `${apiUrl}/${ENV().clientId}`
    }

    static SINGLE(id, model) {
        const single = axios.get(`${CrudHelper.apiUrl()}/${model}/${id}`, { progress: false })
        return single
    }

    static GET_SINGLE_BY_SLUG(model, slugName) {
        const slug = `${model}/slug/${slugName}`
        return CrudHelper.GET(slug)
    }
    static GET_SINGLE_BY_PAGEID(model, pageId) {
        const slug = `${model}/pageid/${pageId}`
        return CrudHelper.GET(slug)
    }

    static async GET(model, params = {}) {
        const progressParams = { progress: false }
        const apiUrl = `${CrudHelper.apiUrl()}/${model}`
        try {
            const {data} = await axios.get(apiUrl, {params: {...params, ...progressParams}})
            return {
                data,
                results: data
            }
        } catch (e) {
            console.log('-----------------------------')
            console.log(`Error access API ${apiUrl}`)
            console.log(`Error log ${e}`)
            console.log('-----------------------------')
            CrudHelper.ERROR_LOG(apiUrl, e)
            return null
        }
    }


    static async GET_EX(path) {
        try {
            const {data} = await axios.get(path)
            return {
                data,
                results: data
            }
        } catch (e) {
            console.log('-----------------------------')
            console.log(`Error access API ${apiUrl}`)
            console.log(`Error log ${e}`)
            console.log('-----------------------------')
            CrudHelper.ERROR_LOG(path, e)
            return null
        }
    }

    static async GET_WITH_TOKEN(model, params = {}, token) {
        try {
            const {data} = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                url: `${CrudHelper.apiUrl()}/${model}`,
                params: params
            })

            if (data) return data
            return null
        } catch (e) {
            return CrudHelper.ERROR_LOG(model, e)
        }
    }

    static async GETSELF(token, global = true) {
        try {

            let selfUrl = ''

            if (global) selfUrl = '/g'

            const {data} = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                url: `${CrudHelper.apiUrl()}${selfUrl}/self`
            })


            if (data) return data
            return null

        } catch (e) {
            CrudHelper.ERROR_LOG('self', e)
        }
    }

    static GET_SELF_CHAIN_HOTEL(token) {
        const env = ENV()
        let url = env.api_base

        if (!url && env.apiUrl) url = env.apiUrl

        return axios({
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            },
            url: `${url}/self`
        })
    }

    static POST(model, params) {
        return axios.post(`${CrudHelper.apiUrl()}/${model}`, params)
    }


    static ERROR_LOG(apiUrl, e) {
        console.log('-----------------------------')
        console.log(`Error access API ${CrudHelper.apiUrl()}/${apiUrl}`)
        console.log(`Error log ${e}`)
        console.log('-----------------------------')

        return null
    }
}
