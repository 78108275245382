//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {NavigationMixin} from "@/mixin/helpers/NavigationMixin"
import {mapActions, mapState} from 'vuex'
import {Expo, TweenMax} from "gsap"
import {LanguageMixin} from "@/mixin/helpers/LanguageMixin"

export default {
    name: "HeaderComponent",
    mixins: [NavigationMixin, LanguageMixin],
    data() {
        return {
            inCareer: false,
            isMobile:false
        }
    },
    computed: {
        ...mapState({
            invertMenu: state => state.Animation.invertMenu,
            isLanguageSelectorOpen: state => state.Animation.isLanguageSelectorOpen,
            loadingCompleted: state => state.SmoothScroll.loadingCompleted,
        }),
        menu() {
            return this.escapedStaticText('header - menu')
        },
        enquire() {
            return this.escapedStaticText('header - enquire')
        },
        newLaunch() {
            return this.escapedStaticText('header - new launch')
        },
        book() {
            return this.escapedStaticText('header - book your stay')
        }
    },
    watch: { 
        loadingCompleted(val) {
            if (val) {
                this.handleAnimateHeader()
            }
        },
        isLanguageSelectorOpen(val) {
            if (val) {
                TweenMax.to('.lang__picker', .1, {
                    color: '#001e31',
                    ease: Expo.easeOut
                })

                TweenMax.to('#down-arrow', .1, {
                    stroke: '#001e31',
                })

                TweenMax.to('.lang__picker svg', .25, {
                    rotation: 180
                })
            } else {
                TweenMax.to('.lang__picker', .1, {
                    color: '#fff',
                    ease: Expo.easeIn
                })

                TweenMax.to('#down-arrow', .1, {
                    stroke: '#fff',
                })

                TweenMax.to('.lang__picker svg', .25, {
                    rotation: 0
                })
            }
        }
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            if (this.isLanguageSelectorOpen) {
                this.hideLanguageSelector()
                setTimeout(next, 500)
            } else {
                next()
            }
        })

        this.isMobile = window.innerWidth < 768
    },
    methods: {
        ...mapActions({
            showEnquiry: 'Enquiry/show',
            openMenu: 'openMenu',
            showLanguageSelector: 'showLanguageSelector',
            hideLanguageSelector: 'hideLanguageSelector',
        }),
        search() {
            this.$store.commit('setSearchOpen', true)
        },


        handleAnimateHeader(){
            /**
            * animate header
            * after route link clicked
            */
            const links = [...document.querySelectorAll("a")]
            links.forEach(link => {
                if(link.getAttribute("href") !== null ? link.getAttribute("href").charAt(0) == "/" : false) {
                    if(this.$route.path == link.getAttribute("href")) {
                        link.removeEventListener('click', ()=> this.animateHeader(link))
                        link.classList.add('no-click')}
                    else {
                        link.removeEventListener('click', ()=> this._animateHeader(link))
                        link.addEventListener('click', ()=> this._animateHeader(link))
                        link.classList.remove('no-click')}
                }
            })
        },

        _animateHeader(el) {
            if(this.$route.path == el.getAttribute("href")) return
            const header = document.querySelector('.header-wrap')

            header.classList.remove('scrolled')
            this.$store.commit('setLoadingShown', false);
        }
    }
}
