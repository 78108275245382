import axios from "axios"

export const WeatherStore = {
    namespaced: true,
    state: {
        onRequest: false,
        weather: {
            temperature: 24,
            description: 'cloudy'
        }
    },

    mutations: {
        setRequest(state, payload) {
            state.onRequest = payload;
        },
        setWeather(state, payload) {
            state.weather = payload;
        },
    },

    actions: {
        requestWeather({ state, commit }, city) {
            if (state.onRequest === true)
                return new Promise(() => state.weather);

            commit('setRequest', true);

            const API_KEY = '5d6a6f01e3a428144258323f49c2e7aa';
            const endpoint = `https://api.openweathermap.org/data/2.5/weather?q=${encodeURI(city)}&units=metric&appid=${API_KEY}`;

            return axios(endpoint)
                .then(response => {
                    const data = response.data;
                    let description = '';

                    if (data.weather.length > 0) {
                        description = data.weather[0].description;
                    }

                    const weather = {
                        temperature: data.main.temp,
                        description: description
                    }

                    commit('setWeather', weather);

                    return weather;
                })
        }
    }
}

