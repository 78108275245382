//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from "@/components/partials/inputs/VInput/VInput"

export default {
    name: "VEmail",
    components: {VInput},
    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
}
