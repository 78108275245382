//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"

export default {
    name: "Error",
    mixins: [GlobalMixin]
}
