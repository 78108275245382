//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {VueSelect} from 'vue-select'
import phone from '~/assets/data/phone.json'
import {GlobalMixin} from "~/mixin/GlobalMixin";
import {mapState} from "vuex";

export default {
    name: "Input",
    components: {VSelect: VueSelect},
    mixins: [GlobalMixin],
    props: {
        type: {
            type: String,
            required: false,
            default: 'Text'
        },
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
    data: () => ({
        phoneCode: null,
        hasDuplicate: false,
        opened: false,
    }),
    watch: {
        opened(val) {
            if (val) {
                this.smoothScroll.stop()
            } else {
                this.smoothScroll.start()
            }
        }
    },
    computed: {
        phones() {
            return phone
        },
        ...mapState({
            smoothScroll: state => state.SmoothScroll.smoothScroll,
        })
    },
    mounted() {
        this.$root.$on('hasDuplicate', (val) => this.hasDuplicate = val);
    }
}
