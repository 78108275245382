//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {LanguageMixin} from "~/mixin/helpers/LanguageMixin"
import {mapActions, mapState} from "vuex"
import {Expo} from "gsap"
import TweenMax from "gsap"

export default {
    name: "LanguageSelector",
    mixins: [LanguageMixin],
    data: () => ({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        hideTimeout: null,
        bgColor: null
    }),
    computed: mapState({
        isLanguageSelectorOpen: state => state.Animation.isLanguageSelectorOpen
    }),
    mounted() {
        this.updateLangPickerPosition()

        window.addEventListener('resize', () => {
            this.updateLangPickerPosition()
        })

        window.addEventListener('mousemove', (e) => {
            const c = e.target.getAttribute('class')

            if (c == null) return
            if (this.hideTimeout != null) {
                clearTimeout(this.hideTimeout)
                this.hideTimeout = null
            }

            this.hideTimeout = setTimeout(() => {
                if (!c.includes('lang__selector') &&
                    !c.includes('lang__picker')
                ) {
                    this.hideLanguageSelector()
                }

                this.hideTimeout = null
            }, 100)
        })
    },
    methods: {
        ...mapActions({
            hideLanguageSelector: 'hideLanguageSelector'
        }),
        updateLangPickerPosition() {
            this.bgColor = document.querySelector('.main_background')
                .getAttribute('class').replace('main_background', '')
                .trim()

            if (this.bgColor !== '') {
                this.$refs.backdrop.classList.add(this.bgColor)
            }

            const langPicker = document.querySelector('.lang__picker')

            if (langPicker == null) {
                return;
            }

            const rect = langPicker.getBoundingClientRect()
            this.left = rect.x - 10
            this.top = rect.y + rect.height

            this.x = rect.x
            this.y = rect.y
            this.height = rect.height

            if (document.querySelector('#headerBg').classList.contains('scrolled')) {
                this.top += 23
                this.y += 23
            }
        },
        onLanguageSelectorEnter(el, done) {
            this.updateLangPickerPosition()

            const rectSelector = this.$refs.languageSelector.getBoundingClientRect()
            this.width = rectSelector.width

            TweenMax.to(this.$refs.backdrop, .5, {
                height: rectSelector.height + this.height,
                alpha: 1,
                webkitBackdropFilter: 'saturate(180%) blur(30px)',
                ease: Expo.easeOut
            })

            TweenMax.fromTo(this.$refs.languageSelector, .5, {
                clipPath: 'inset(0 0 100% 0)'
            }, {
                clipPath: 'inset(0 0 0% 0)',
                ease: Expo.easeOut,
                onComplete: done
            })
        },
        onLanguageSelectorLeave(el, done) {
            TweenMax.to(this.$refs.languageSelector, .5, {
                clipPath: 'inset(0 0 100% 0)',
                ease: Expo.easeIn,
                onComplete: () => {
                    if (this.bgColor != null && this.bgColor !== '') {
                        this.$refs.backdrop.classList.remove('cream', 'grey', 'green', 'gold', 'white', 'bluish')
                        this.bgColor = null
                    }

                    done()
                }
            })

            TweenMax.to(this.$refs.backdrop, .5, {
                height: this.height,
                alpha: 0,
                webkitBackdropFilter: 'saturate(0%) blur(0px)',
                ease: Expo.easeIn
            })
        }
    }
}
