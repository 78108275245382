//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import {mapState, mapActions} from 'vuex'
import {TweenMax, TimelineMax} from 'gsap'

export default {
    name: "LightboxComponent",
    mixins: [GlobalMixin],
    data() {
        return {
            open: false,
            width: 0
        }
    },
    computed: mapState({
        enlarge: state => state.Lightbox.enlarge
    }),
    watch: {
        enlarge(val) {
            if (val) {
                this.open = true
                this.$nextTick(() => {
                    const tl = new TimelineMax

                    tl.from(this.$refs.backdrop, .5, {
                        opacity: 0
                    })

                    tl.fromTo(this.$refs.lightbox, .5, {
                        scale: .9,
                        clipPath: 'inset(0 100% 0 0)',
                    }, {
                        scale: 1,
                        clipPath: 'inset(0 0% 0 0)'
                    }, '-=.25')
                })
            } else {
                TweenMax.to(this.$refs.lightbox, .5, {
                    alpha: 0,
                    onComplete: () => this.open = false
                })
            }

        }
    },
    mounted() {
        this.width = window.innerWidth
    },
    methods: {
        ...mapActions({
            hide: 'Lightbox/hide',
        }),
        onMouseMove(e) {
            TweenMax.to(this.$refs.image, .5, {
                yPercent: e.clientY / window.innerWidth * -100
            })
        }
    }
}
