//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TweenMax, TimelineMax, Expo} from 'gsap'
import {mapActions, mapState} from 'vuex'
import {GlobalMixin} from "@/mixin/GlobalMixin"
import {NavigationMixin} from "@/mixin/helpers/NavigationMixin"
import {LanguageMixin} from "@/mixin/helpers/LanguageMixin"

export default {
    name: "MenuComponent",
    mixins: [GlobalMixin, NavigationMixin, LanguageMixin],
    data() {
        return {
            isTouch: false,
            open: false,
            main: {menu: []},
            hoverTimeout: null,
            sub: {
                first: null,
                second: null,
                firstSelected: null,
                secondSelected: null,
                firstOpen: false,
                secondOpen: false,
            },
            width: null,
            isLanguageSelectorOpen: false,
            bgColor: null
        }
    },
    computed: {
        ...mapState({
            isMenuOpen: state => state.Animation.isMenuOpen,
        }),
        close() {
            const text = this.getStaticText('command - close')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    watch: {
        /***
         * on menu open
         */
        open(val) {
            this.$nextTick(() => {
                if (val) {
                    const sidebarMenu = document.querySelector('.sidebar__menu')
                    this.bgColor = document.querySelector('.main_background')
                        .getAttribute('class').replace('main_background', '')
                        .trim()

                    if (this.bgColor !== '') {
                        sidebarMenu.classList.add(this.bgColor)
                    }
                }

                let clipDest = '75%'

                if (window.innerWidth < 769) {
                    clipDest = '0%'
                }

                TweenMax.fromTo('.sidebar__menu-container', .8, {
                    clipPath: 'inset(0 100% 0 0)',
                    webkitClipPath: 'inset(0 100% 0 0)',
                    ease: Expo.easeOut
                }, {
                    clipPath: `inset(0 ${clipDest} 0 0)`,
                    webkitClipPath: `inset(0 ${clipDest} 0 0)`,
                    ease: Expo.easeOut
                })

                TweenMax.staggerFrom('.sidebar__menu-wrapper-1 .sidebar__menu-primary__link',
                    .5, {
                        xPercent: -5,
                        opacity: 0
                    }, .05)

                TweenMax.from('.sidebar__menu .backdrop', .5, {
                    opacity: 0,
                })
            })
        },
        isMenuOpen(val) {
            const tl = new TimelineMax()

            if (val) {
                /***
                 * menu open, see watch --> open()
                 */
                this.open = true
            } else {
                /***
                 * menu close
                 */

                tl.to('.sidebar__menu-container', .8, {
                    clipPath: 'inset(0 100% 0 0)',
                    webkitClipPath: 'inset(0 100% 0 0)',
                    ease: Expo.easeInOut
                }, '-=.20')

                tl.to('.sidebar__menu .backdrop', .8, {
                    opacity: 0,
                }, '-=.70')

                tl.eventCallback('onComplete', () => {
                    this.open = false

                    this.sub = {
                        first: null,
                        second: null,
                        firstSelected: null,
                        secondSelected: null,
                        firstOpen: false,
                        secondOpen: false,
                    }

                    const sidebarMenu = document.querySelector('.sidebar__menu')

                    if (this.bgColor != null && this.bgColor !== '') {
                        sidebarMenu.classList.remove(this.bgColor)
                        this.bgColor = null
                    }
                })
            }
        },
    },
    mounted() {
        this.main = this.getNavigationByID('main')
        /**
         * @since removed preloader
         */ 

        // this.$router.beforeEach((to, from, next) => {
        //     if (this.isMenuOpen) {
        //         this.hideMenu()
        //         setTimeout(next, 800)
        //     } else {
        //         next()
        //     }
        // })
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
        })
    },
    methods: {
        ...mapActions([
            'openMenu', 'hideMenu'
        ]),

        handleHideMenu(e) {
            if(this.$route.path == e.target.dataset.to) return
            if(this.$route.path == e.target.getAttribute("href")) return

            const header = document.querySelector('.header-wrap')
           
            header.classList.remove('scrolled')
            this.hideMenu()
            this.$store.commit('setLoadingShown', false);
        },

        onDropdownEnter(el, done) {
            const targets = el.querySelectorAll('.sidebar__menu-primary__dropdown-item')

            TweenMax.staggerFrom(targets, .5, {
                xPercent: -5, opacity: 0,
                onComplete: done
            }, 0.05)
        },
        onLangDropdownEnter(el, done) {
            TweenMax.to(this.$refs.langPicker, .5, {
                backgroundColor: 'rgba(255, 255, 255, 1)',
                borderBottom: '1px solid rgba(201, 201, 201, 1)',
                onComplete: done
            })

            TweenMax.fromTo('.sidebar__menu-lang__dropdown-dropdown', .5, {
                clipPath: 'inset(0 0 100% 0)',
                webkitClipPath: 'inset(0 0 100% 0)'
            }, {
                clipPath: 'inset(0 0 0% 0)',
                webkitClipPath: 'inset(0 0 0% 0)',
                ease: Expo.easeOut
            })

            TweenMax.to('.sidebar__menu-lang a', .5, {
                color: '#001e31'
            })

            TweenMax.to('#down-arrow', .5, {
                stroke: '#001e31'
            })
        },
        onLangDropdownLeave(el, done) {
            let tl = new TimelineMax

            tl.to('.sidebar__menu-lang__dropdown-dropdown', .5, {
                clipPath: 'inset(0 0 100%)',
                webkitClipPath: 'inset(0 0 100%)',
                ease: Expo.easeIn
            })

            tl.to(this.$refs.langPicker, .5, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderBottom: '1px solid rgba(201, 201, 201, 0)',
                onComplete: done
            })

            tl.to('.sidebar__menu-lang a', .5, {
                color: '#fff'
            }, '-=.5')

            tl.to('#down-arrow', .5, {
                stroke: '#fff'
            }, '-=.5')
        },
        openLanguageSelector() {
            this.isLanguageSelectorOpen = !this.isLanguageSelectorOpen
        },
        onMenuBack() {
            const tl = new TimelineMax

            tl.staggerTo('.sidebar__menu-wrapper-2 .sidebar__menu-primary__link',
                .5, {
                    xPercent: 5,
                    opacity: 0,
                    onComplete: () => {

                        this.sub.first = []
                        this.sub.firstOpen = false
                        this.sub.second = []
                        this.sub.secondOpen = false
                        this.sub.secondSelected = null
                    }
                }, .05)

            tl.staggerTo('.sidebar__menu-wrapper-1 .sidebar__menu-primary__link',
                .5, {
                    xPercent: 0,
                    opacity: 1
                }, .05)
        },
        onSecondBack() {
            const tl = new TimelineMax

            tl.staggerTo('.sidebar__menu-wrapper-3 .sidebar__menu-primary__link',
                .5, {
                    xPercent: 5,
                    opacity: 0,
                    onComplete: () => {
                        this.sub.second = []
                        this.sub.secondOpen = false
                    }
                }, .05)

            tl.staggerTo('.sidebar__menu-wrapper-2 .sidebar__menu-primary__link',
                .5, {
                    xPercent: 0,
                    opacity: 1
                }, .05)
        },
        onSecondHover(e, link, index) {
            if (this.hoverTimeout != null) {
                clearTimeout(this.hoverTimeout)
                this.hoverTimeout = null
            }

            if (window.innerWidth >= 769 && e.detail >= 1 && !this.isTouch) {
                this.handleHideMenu(e)
                this.navigate(e.target.dataset.to)
                return
            }

            this.hoverTimeout = setTimeout(() => {
                this.hoverTimeout = null

                if (this.sub.secondSelected === index) {
                    return
                }

                this.sub.secondSelected = index

                let tl = new TimelineMax
                let child = this.main.menu[this.sub.firstSelected].child[index].child

                if (child ? child.length > 0 && window.innerWidth >= 769 : false) {
                    this.sub.secondOpen = true
                } else if (child.length === 0 && (window.innerWidth < 769 || this.isTouch)) {
                    this.isTouch = false
                    this.handleHideMenu(e)
                    this.navigate(e.target.dataset.to)
                    return
                }

                this.isTouch = false
                this.sub.second = child
                this.$nextTick(() => this.staggerSecond(tl))
            }, 500)
        },
        navigate(to) {
            if (
                to.startsWith("http") ||
                to.endsWith(".pdf") ||
                to.startsWith("mailto") ||
                to.startsWith("tel")
            ) {
                window.open(to, '_blank').focus()
            } else {
                this.$router.push(to)
            }
        },
        onFirstHover(e, link, index) {
            if (this.hoverTimeout != null) {
                clearTimeout(this.hoverTimeout)
                this.hoverTimeout = null
            }

            if (e.detail >= 1) {
                e.preventDefault()
            }

            if (window.innerWidth >= 769 && e.detail >= 1 && !this.isTouch) {
                this.handleHideMenu(e)
                this.navigate(e.target.dataset.to)
                return
            }

            this.hoverTimeout = setTimeout(() => {
                this.hoverTimeout = null

                if (this.sub.firstSelected === index && e.detail === 0) {
                    return
                }

                this.sub.firstSelected = index

                let first = false

                if (!this.sub.firstOpen) {
                    first = true
                }

                let tl = new TimelineMax
                let child = this.main.menu[index].child

                /***
                 * Desktop only, because hide animation of
                 * .sidebar__menu-wrapper-1 .sidebar__menu-primary__link
                 * wouldn't shown if this.sub.firstOpen set to true
                 */
                if (child.length > 0 && window.innerWidth >= 769) {
                    this.sub.firstOpen = true
                } else if (child.length === 0 && (window.innerWidth <= 768 || this.isTouch)) {
                    this.isTouch = false
                    this.handleHideMenu(e)
                    this.navigate(e.target.dataset.to)
                    return
                }

                if (first) {
                    let clipDest = '50%'

                    if (window.innerWidth < 769) {
                        clipDest = '0%'
                    }

                    TweenMax.to('.sidebar__menu-container', .8, {
                        clipPath: `inset(0 ${clipDest} 0 0)`,
                        webkitClipPath: `inset(0 ${clipDest} 0 0)`,
                        ease: Expo.easeOut
                    })

                    if (window.innerWidth < 769) {
                        /***
                         * animate hide on navigate to sub menu, not used on desktop
                         * mobile navigation always first time, see
                         * @type first
                         */
                        TweenMax.to('.sidebar__menu-wrapper-1 .sidebar__menu-primary__link',
                            .5, {
                                xPercent: -5,
                                opacity: 0,
                                onComplete: () => {
                                    if (window.innerWidth < 769) {
                                        this.sub.firstOpen = true
                                    }

                                    this.isTouch = false
                                    this.sub.first = child
                                    this.$nextTick(() => this.staggerFirst(tl))
                                }
                            })
                    } else {
                        this.isTouch = false
                        this.sub.first = child
                        this.$nextTick(() => this.staggerFirst(tl))
                    }
                } else {
                    this.sub.secondSelected = null

                    if (this.sub.secondOpen) {
                        TweenMax.to('.sidebar__menu-container',
                            .8, {
                                clipPath: 'inset(0 50% 0 0)',
                                webkitClipPath: 'inset(0 50% 0 0)',
                                ease: Expo.easeInOut,
                                onComplete: () => {
                                    this.sub.second = []
                                    this.sub.secondOpen = false
                                }
                            })
                    }

                    this.isTouch = false
                    this.sub.first = child
                    this.$nextTick(() => this.staggerFirst(tl))
                }
            }, 500)
        },
        staggerFirst() {
            if (this.sub.first.length === 0) {
                TweenMax.to('.sidebar__menu-container', .8, {
                    clipPath: 'inset(0 75% 0 0)',
                    webkitClipPath: 'inset(0 75% 0 0)',
                    ease: Expo.easeInOut,
                    onComplete: () => {
                        this.sub.firstOpen = false
                    }
                })
            }

            TweenMax.staggerFromTo('.sidebar__menu-wrapper-2 .sidebar__menu-primary__link', .5, {
                xPercent: -5,
                opacity: 0,
            }, {
                xPercent: 0,
                opacity: 1,
            }, .05)
        },
        staggerSecond() {
            if (this.sub.second.length === 0) {
                TweenMax.to('.sidebar__menu-container', .8, {
                    clipPath: 'inset(0 50% 0 0)',
                    webkitClipPath: 'inset(0 50% 0 0)',
                    ease: Expo.easeInOut,
                    onComplete: () => {
                        this.sub.secondOpen = false
                    }
                })
            }

            TweenMax.staggerFromTo('.sidebar__menu-wrapper-3 .sidebar__menu-primary__link',
                .5, {
                    xPercent: -5,
                    opacity: 0
                }, {
                    xPercent: 0,
                    opacity: 1
                }, .05)
        }
    },
}
