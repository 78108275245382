export function eq(index) {
    if (index >= 0 && index < this.length)
        return this[index];
    else
        return -1;
}

export function triggerResize() {
    var ev = document.createEvent('HTMLEvents');
    ev.initEvent('resize', true, false);
    window.dispatchEvent(ev);
}

export function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i = 0; i < children.length; i++) {
        if (children[i] == node) return num;
        if (children[i].nodeType == 1) num++;
    }
    return -1;
}
