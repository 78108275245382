import { render, staticRenderFns } from "./VSelectOptions.vue?vue&type=template&id=2a406270&scoped=true&"
import script from "./VSelectOptions.vue?vue&type=script&lang=js&"
export * from "./VSelectOptions.vue?vue&type=script&lang=js&"
import style0 from "./VSelectOptions.scss?vue&type=style&index=0&id=2a406270&lang=scss&scoped=true&"
import style1 from "@riophae/vue-treeselect/dist/vue-treeselect.css?vue&type=style&index=1&lang=css&"
import style2 from "./VSelectOptions.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a406270",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/var/www/frontend-lustica-nuxt/components/tpl/template.js').default})
