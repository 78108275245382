import Vue from 'vue'
import {createComponent} from "../functions/helpers"


Vue.directive('vold-youtube-editor', {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        const {value} = binding
        const isUserCanEdit = vnode.context.$store.getters.getCurrentUserCanEditContent
        const async = () => ({
            component: import('../component-editor/LinkEditor/LinkEditorForm/LinkEditorForm')
        })

        if (isUserCanEdit) {
            el.addEventListener('click', event => {
                event.preventDefault()
                event.stopPropagation()

                createComponent(async().component, $comp => {
                    const root = context.$root.$el

                    root.appendChild($comp.$el)

                    $comp.$on('onClose', newValue => {
                        $comp.$el.parentNode.removeChild($comp.$el)
                    })

                }, {
                    isActive: true,
                    model: value.model,
                    forms: [
                        {
                            label: 'Video Url',
                            model: 'url',
                            component: 'FormInput'
                        }
                    ],
                    title: 'Youtube'
                })

            })
        } else {
            if (value.action) {

                el.addEventListener('click', event => {
                    event.preventDefault()
                     event.stopPropagation()
                    value.action()
                });
               
            }
        }

    }
})
