import imagesLoaded from "imagesloaded"
import { mapState } from 'vuex'

const LoadingMixin = {
    data() {
        return {
            loaded: 0,
            progress: 0,
            container: null,
            imgLoad: null,
            done: false
        }
    },
    computed: mapState({
        loadingShown: state => state.Animation.isLoadingShown,
    }),
    methods: {
        loadProgress(imagesToLoad) {
            this.loaded++
            this.progress = Math.round((this.loaded / imagesToLoad) * 100)

            if (this.progress === 100 && !this.done) {
                this.done = true
                this.doneLoading()
            }
        },
        doneLoading() {
            this.$store.commit('setDetailLoaded', false);
            this.imgLoad = null
            this.done = false
            this.$store.commit('setLoadingCompleted', true);
        },
    },
    mounted() {
        this.$store.commit('setLoadingCompleted', false);

        this.container = document.querySelector('#mainScroll');

        this.imgLoad = imagesLoaded(this.container, { background: '.image-preload' })

        if (this.imgLoad.images.length === 0) {
            this.doneLoading()
        }

        this.imgLoad.on('progress', (instance) => {
            this.loadProgress(instance.images.length)
        })
    }
}

export default LoadingMixin
