import {CrudHelper} from "../vold-core/functions/Crudhelper"

export class BaseClass {
    constructor(ctx) {
        this.context = ctx
        this.websiteData = {}
    }

    checkingObjectValue(checker, checking) {
        const values = Object.values(checker)
        return values.indexOf(checking) > -1
    }

    init() {

    }

    getSingle() {

    }

    getIndex() {

    }

    async getDetailPage(slug) {
        const {data} = await CrudHelper.GET_SINGLE_BY_SLUG('page', encodeURI(slug));
        return data
    }

    async getDetailPost(slug) {
        const {data} = await CrudHelper.GET_SINGLE_BY_SLUG('post', encodeURI(slug));
        return data
    }

    async getDetailPageByID(pageId) {
        const {data} = await CrudHelper.SINGLE(pageId, 'page');
        return data
    }

}
