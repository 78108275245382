import {BaseClass} from "./BaseClass"

export class PostClassMiddleware extends BaseClass {
    constructor(ctx, parent) {
        super(ctx)
        this.parent = parent
    }

    async init() {
        super.init()
        this.getSingle()
        await this.getIndex()
    }

    getSingle() {
        super.getSingle()
    }

    async getIndex() {
        super.getIndex()
        // const {data} = await CrudHelper.GET('post')
        // this.websiteData.posts = data.results

    }
}
