import ENV from '../../environment/ENV'
import Vue from 'vue'

export function copy(o) {
    let output, v, key
    output = Array.isArray(o) ? [] : {}
    for (key in o) {
        v = o[key]
        output[key] = (typeof v === 'object') ? copy(v) : v
    }
    return output
}


export function ArrayMove(arr, fromIndex, toIndex, insert) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)

    return arr
}

export function getPostMessage(cb) {

    window.addEventListener('message', (ev) => {
        if (ev.origin !== ENV().client_origin) return

        if (cb) cb(ev.data)
    })
}

export function sendPostMessage(key, value) {
    window.parent.postMessage({
        key: key,
        message: value
    }, ENV().client_origin)
}

export function isEmptyObject(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key))
            return false
    }
    return true
}


export function renderMultipleLanguageValue(content, language) {
    if (content && typeof content === 'object') {
        const contentValue = Object.values(content)
        if (language in content) {
            return content[language]
        }

        /**
         * If current selected language does not exist in content
         * return from default value or if not available
         * return from any existing value
         */
        else {
            if (language !== 'en' && 'en' in content) {
                return content['en']
            } else {
                return contentValue[0]
            }
        }
    } else {
        return content || ''
    }
}

export const isUserCanEdit = (store) => {

    return store.getters.getCurrentUserCanEditContent
}


export const createComponent = (component, cb, model) => {

    component.then(comp => {
        const newComp = Vue.extend(comp.default)
        const form = new newComp()
        if (model) {
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    form[key] = model[key]
                }
            }
        }
        form.$mount()
        if (cb) cb(form)
    })

}
