//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "VTextarea",
    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    }
}
