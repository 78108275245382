export const EnquiryStore = {
    namespaced: true,
    state: {
        show: false,
    },

    mutations: {
        setShow(state, payload) {
            state.show = payload;
        }
    },
    actions: {
        show({ commit }) {
            return commit('setShow', true);
        },
        hide({ commit }) {
            return commit('setShow', false);
        }
    },
}
