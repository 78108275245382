//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {GlobalMixin} from "@/mixin/GlobalMixin"
import {CrudHelper} from "@/vold-core/functions/Crudhelper"
export default {
    name: "PopupComponent",
    mixins:[GlobalMixin],
    async fetch(){
     
                const currentPageId = this.getPageData.single.id
               const response = await CrudHelper.GET(`post?sort=id,DESC&where={"type":"popup","data.page":{"$elemMatch":{"id":"${currentPageId}"}},"data.active":true}`)
               if(response.data && response.data.results.length > 0){
                   this.popupDetail = response.data.results[0]
               }
    },
    watch:{
        'getPageData.single':{
            handler(){
                this.$fetch()
                 setTimeout(() => {
                        if(this.popupDetail){
                                this.show  = true       
                        }                
                    }, 5000);
            }
        }
    },
    computed:{
        close() {
            const text = this.getStaticText('command - close')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    data(){

            return {
                popupDetail:null,
                show:false
            }
    },
    methods:{
        closePopup(){
            this.popupDetail = null
            this.show = false
        }
    },
    mounted(){
                    setTimeout(() => {
                        if(this.popupDetail){
                                this.show  = true       
                        }                
                    }, 3000);
               
    }
}
