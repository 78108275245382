import Vue from 'vue'
import _ from 'lodash'

Vue.directive('add-more', {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        let {model} = binding.value
        const userCanEdit = context.$store.getters.getCurrentUserCanEditContent

        if (userCanEdit) {

            const buttonContainer = document.createElement('div')
            buttonContainer.classList.add('add-more-button')

            const button = document.createElement('div')
            button.classList.add('button')
            button.innerHTML = '<svg viewBox="0 0 31.444 31.444"><path d="M1.119 16.841a1.118 1.118 0 01-1.111-1.127c0-.619.492-1.111 1.111-1.111h13.475V1.127A1.133 1.133 0 0115.722 0c.619 0 1.111.508 1.111 1.127v13.476h13.475c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H16.833v13.476c0 .619-.492 1.127-1.111 1.127a1.131 1.131 0 01-1.127-1.127V16.841H1.119z" fill="#ffffff"></path></svg>'

            buttonContainer.appendChild(button)
            el.appendChild(buttonContainer)

            button.addEventListener('click', () => {
                const newItem = _.cloneDeep(model[model.length - 1])
                model.push(newItem)
            })
        }
    }
})


Vue.directive('add-more-delete', {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        const {$root} = context
        let {model, pos} = binding.value
        const userCanEdit = context.$store.getters.getCurrentUserCanEditContent

        if (userCanEdit) {
            const buttonContainer = document.createElement('div')
            buttonContainer.classList.add('add-more-button-delete')

            const button = document.createElement('div')
            button.classList.add('button')
            button.innerHTML = `<svg viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><rect fill="#FFFFFF" y="236" width="512" height="40"/></g></g></svg>`
            buttonContainer.appendChild(button)
            el.appendChild(buttonContainer)

            button.addEventListener('click', () => {
                model.splice(pos, 1)
            })
        }
    }
})


Vue.directive('add-more-delete-item', {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        const {state} = binding.value
        const userCanEdit = context.$store.getters.getCurrentUserCanEditContent

        if (userCanEdit) {
            const button = document.createElement('div')
            button.innerText = 'Hello'
            el.appendChild(button)

            button.addEventListener('click', () => {
                if (state) state()
            })
        }
    }
})
