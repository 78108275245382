export const WebsiteContentStore = {
    state: {
        currentLanguage: 'en',
        defaultLanguage: 'en',

        /**
         * Storing website config
         */
        websiteConfig: null,

        websiteNavigation: null,
    },

    mutations: {
        setWebsiteConfig: (state, payload) => {
            state.websiteConfig = payload
        },

        setSelectedWebsiteLanguage: (state, payload) => {
            state.currentLanguage = payload
        },

        setWebsiteNavigation: (state, payload) => {
            state.websiteNavigation = payload
        }
    },

    getters: {
        getWebsiteConfig: state => state.websiteConfig,
        getSelectedWebsiteLanguage: state => state.currentLanguage,
        getWebsiteNavigation: state => state.websiteNavigation
    }
}
