import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _59f0b83e = () => interopDefault(import('../pages/vold/content/editor/post/editor.vue' /* webpackChunkName: "pages/vold/content/editor/post/editor" */))
const _61f56676 = () => interopDefault(import('../pages/vold/content/editor/post/_id.vue' /* webpackChunkName: "pages/vold/content/editor/post/_id" */))
const _4fc6dbb6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19ddf288 = () => interopDefault(import('../pages/_language/_slug/index.vue' /* webpackChunkName: "pages/_language/_slug/index" */))
const _0c25d0a6 = () => interopDefault(import('../pages/_language/_slug/_detail.vue' /* webpackChunkName: "pages/_language/_slug/_detail" */))
const _329618cd = () => interopDefault(import('../pages/_language/_type/_featured/_slug.vue' /* webpackChunkName: "pages/_language/_type/_featured/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/vold/content/editor/post/editor",
    component: _59f0b83e,
    name: "vold-content-editor-post-editor"
  }, {
    path: "/vold/content/editor/post/:id?",
    component: _61f56676,
    name: "vold-content-editor-post-id"
  }, {
    path: "/",
    component: _4fc6dbb6,
    name: "index"
  }, {
    path: "/:language/:slug",
    component: _19ddf288,
    name: "language-slug"
  }, {
    path: "/:language/:slug?/:detail",
    component: _0c25d0a6,
    name: "language-slug-detail"
  }, {
    path: "/:language/:type?/:featured?/:slug?",
    component: _329618cd,
    name: "language-type-featured-slug"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
