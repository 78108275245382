export const EditorStore = {
    state: {
        isCurrentUserCanEditContent: false,
        userToken: null,
        sliderManager: null,
        isFromThemeEditor:false
    },

    mutations: {
        setCurrentUserCanEditContent: (state, payload) => {
            state.isCurrentUserCanEditContent = payload
        },

        setUserToken: (state, payload) => {
            state.userToken = payload
        },

        setSliderManager: (state, payload) => {
            state.sliderManager = payload
        },
        setIsFromThemeEditor:(state,payload)=>{
            state.isFromThemeEditor = payload
        }
    },

    getters: {
        getCurrentUserCanEditContent: state => state.isCurrentUserCanEditContent,
        getUserToken: state => state.userToken,
        getSliderManager: state => state.sliderManager,
        isFromThemeEditor:state=>state.isFromThemeEditor
    }
}
