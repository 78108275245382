const thumbnailPath = "/assets/thumbnails/"

export const COMPONENTS = [
    {
        name: "Hero Video Slider",
        module_name: "HeroVideoSliderComponent",
        component_name: "HeroVideoSliderComponent",
        thumbnail: `${thumbnailPath}HeroVideoSliderComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "0px",
                    bottom: "0px"
                },
                overlay: "0.3"
            }
        },
        model: {
            slide1: {
                title: "The Town you<br>Call Home",
                link: {
                    title: "Watch Video",
                    url: "#",
                    external: false
                }
            },
            slide2: {
                title: "Unforgettable<br>Experiences",
                link: {
                    title: "Explore",
                    url: "#",
                    external: false
                }
            },
            slide3: {
                title: "The<br>Residences",
                link: {
                    title: "Explore",
                    url: "#",
                    external: false
                }
            }
        }
    },
    {
        name: "Box Intro",
        module_name: "BoxIntroComponent",
        component_name: "BoxIntroComponent",
        thumbnail: `${thumbnailPath}BoxIntroComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "The Beating Heart of Coastal Montenegro",
            subtitle:
                "Designed to reflect the traditional fishing villages of Montenegro, Luštica Bay unites old-world charm with cutting-edge facilities; timeless Riviera living with contemporary luxury. ",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            image: {
                default: null,
                hover: null
            }
        }
    },
    {
        name: "Local Time",
        module_name: "LocalTimeComponent",
        component_name: "LocalTimeComponent",
        thumbnail: `${thumbnailPath}LocalTimeComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {}
    },
    {
        name: "Box (Right)",
        module_name: "BoxRightComponent",
        component_name: "BoxRightComponent",
        thumbnail: `${thumbnailPath}BoxRightComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "About<br>Luštica Bay",
            description:
                "Designed to reflect the traditional fishing villages of Montenegro, Luštica Bay unites old-world charm with cutting-edge facilities; timeless Riviera living with contemporary luxury. ",
            image: null,
            link: {
                title: "Discover",
                url: "#"
            },
            number: "02",
            bgColor: "white",
            logo: null,
            darkBoxText: false
        }
    },
    {
        name: "Image Text (Right)",
        module_name: "ImgTxtRightComponent",
        component_name: "ImgTxtRightComponent",
        thumbnail: `${thumbnailPath}ImgTxtRightComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "160px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "160px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            fontSize: 18,
            title: "The Chedi<br>Hotel",
            description:
                "The five-star hotel - The Chedi Luštica Bay is the first of our seven hotels to take pride of place within Luštica Bay. Set on the front line of the marina, it is a wonderful setting that takes full advantage of waterfront living.",
            link: {
                url: "#",
                title: "Discover",
                external: false
            },
            image: {
                default: null,
                hover: null
            },
            shadow: "rgba(222, 180, 159, 0.25)",
            reversed: false
        }
    },
    {
        name: "Full Banner",
        module_name: "FullBannerComponent",
        component_name: "FullBannerComponent",
        thumbnail: `${thumbnailPath}FullBannerComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "0px",
                    bottom: "0px"
                },
                margin_mobile: {
                    top: "0px",
                    bottom: "0px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Masterplan",
            description:
                "The goal of Luštica Bay is to create a distinct community, within a self-sufficient extraordinary setting, where residents can create a home around the life they want to live.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            background: null
        }
    },
    {
        name: "Single Image",
        module_name: "SingleImageComponent",
        component_name: "SingleImageComponent",
        thumbnail: `${thumbnailPath}FullBannerComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "0px",
                    bottom: "0px"
                },
                margin_mobile: {
                    top: "0px",
                    bottom: "0px",
                },
                overlay: "0.3"
            }
        },
        model: {
            background: null
        }
    },
    {
        name: "Carousel",
        module_name: "CarouselComponent",
        component_name: "CarouselComponent",
        thumbnail: `${thumbnailPath}CarouselComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Residencies",
            description:
                "Luštica Bay offers a wide range of properties, from waterfront apartments, charming hillside townhouses, to exclusive, private villas.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            slider: [
                {
                    title: "Marina Villas",
                    link: {
                        title: "Discover",
                        url: "#",
                        external: false
                    },
                    image: null
                },
                {
                    title: "Marina Villas",
                    link: {
                        title: "Discover",
                        url: "#",
                        external: false
                    },
                    image: null
                },
                {
                    title: "Marina Villas",
                    link: {
                        title: "Discover",
                        url: "#",
                        external: false
                    },
                    image: null
                }
            ]
        }
    },
    {
        name: "Carousel (wide)",
        module_name: "CarouselWideComponent",
        component_name: "CarouselWideComponent",
        thumbnail: `${thumbnailPath}CarouselWideComponent.jpg`,
        model: {
            title: "Waterfront Retail",
            description:
                "Discover an entirely new riviera lifestyle experience in the heart of Luštica Bay’s stunning new resort town. Explore a unique and diverse selection of handpicked restaurants, shops, boutiques and experiences along its vibrant seafront promenade.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            slider: [
                {
                    title: "Health and Lifestyle",
                    link: {
                        title: "Discover",
                        url: "#",
                        external: false
                    },
                    image: null
                }
            ]
        }
    },
    {
        name: "Two Images",
        module_name: "TwoImagesComponent",
        component_name: "TwoImagesComponent",
        thumbnail: `${thumbnailPath}TwoImagesComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Golf",
            description:
                "Luštica Bay’s star leisure attraction will be its 18-hole Gary Player signature golf course. It will be Montenegro’s first 18-hole championship golf course built on one of the world’s most beautiful golfing spots.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            image: {
                big: null,
                small: null
            },
            number: "03",
            bgColor: "green",
            reversed: false
        }
    },
    {
        name: "Three Cards",
        module_name: "ThreeCardsComponent",
        component_name: "ThreeCardsComponent",
        thumbnail: `${thumbnailPath}ThreeCardsComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "0px",
                    bottom: "0px"
                },
                margin_mobile: {
                    top: "0px",
                    bottom: "0px",
                },
                overlay: "0.3"
            }
        },
        model: {
            left: {
                image: null,
                link: {
                    title: "Things to do",
                    url: "#",
                    external: false
                }
            },
            center: {
                image: null,
                link: {
                    title: "Tours",
                    url: "#",
                    external: false
                }
            },
            right: {
                image: null,
                link: {
                    title: "Events",
                    url: "#",
                    external: false
                }
            }
        }
    },
    {
        name: "Text Button",
        module_name: "TextButtonComponent",
        component_name: "TextButtonComponent",
        thumbnail: `${thumbnailPath}TextButtonComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Events and Activities",
            description:
                "Nam illud quidem adduci vix possum, ut ea, quae senserit ille, tibi non vera videantur. Atqui iste locus est, Piso, tibi etiam atque etiam confirmandus.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            }
        }
    },
    {
        name: "Box (Left)",
        module_name: "BoxLeftComponent",
        component_name: "BoxLeftComponent",
        thumbnail: `${thumbnailPath}BoxLeftComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: '80px',
                    bottom: '80px'
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Rental Program",
            description:
                "Unwind and relax in the pristine coastline of Trašte Bay – Book your holiday apartment now and spend your vacation in a dynamic and breathtaking resort, Luštica Bay.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            background: null,
            darkBoxText: false,
            bgColor: "cream",
            number: "01"
        }
    },
    {
        name: "Text Intro",
        module_name: "TextIntroComponent",
        component_name: "TextIntroComponent",
        thumbnail: `${thumbnailPath}TextIntroComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            logo: null,
            title: "Parallel with a vision",
            description: "The development has thus far been unfolding in line " +
                "with its uncompromising vision. With the first phase of the " +
                "development well underway, encompassing the hub of the project, " +
                "the Marina Village, initial plans for Centrale neighbourhood.",
            buttons: [
                {
                    title: "Discover",
                    url: "#",
                    external: false
                }
            ]
        }
    },
    {
        name: "Discover",
        module_name: "DiscoverComponent",
        component_name: "DiscoverComponent",
        thumbnail: `${thumbnailPath}DiscoverComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            bgColor: "cream",
            sliders: [
                {
                    year: 1800,
                    title:
                        "Lustica Bay -<br> Yesterday, Today,<br> And Tomorrow",
                    description:
                        "Longum est enim ad omnia respondere, quae a te dicta sunt. Est tamen ea" +
                        "secundum naturam multoque nos ad. Est tamen ea secundum naturam multoque nos ad.",
                    imageText:
                        "Longum est enim ad omnia respondere, quae a te dicta sunt. Est tamen ea" +
                        "secundum naturam multoque nos ad. Est tamen ea secundum naturam multoque nos ad.",
                    link: {
                        url: "#",
                        title: "Discover",
                        external: false
                    },
                    images: {
                        big: null,
                        small: null
                    }
                }
            ]
        }
    },
    {
        name: "Events",
        module_name: "EventsComponent",
        component_name: "EventsComponent",
        thumbnail: `${thumbnailPath}EventsComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            events: [
                {
                    image: null,
                    number: "01",
                    title: "Diving Courses",
                    description:
                        "Nam illud quidem adduci vix possum, ut ea, quae senserit ille, " +
                        "tibi non vera videantur. Atqui iste locus est, Piso, tibi etiam atque " +
                        "etiam confirmandus, inquam."
                }
            ]
        }
    },
    {
        name: "Stack Gallery",
        module_name: "StackGalleryComponent",
        component_name: "StackGalleryComponent",
        thumbnail: `${thumbnailPath}StackGalleryComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: 'Gallery',
            gallery: [
                {
                    image: null,
                    title: "Living Room",
                    subtitle: "The Chedi Hotel"
                }
            ]
        }
    },
    {
        name: "Banner Dock",
        module_name: "BannerDockComponent",
        component_name: "BannerDockComponent",
        thumbnail: `${thumbnailPath}BannerDockComponent.jpg`,
        model: {
            title: "Iris Apartment",
            background: null,
            columns: [
                {
                    title: "Property Name",
                    content: "Iris Apartment"
                },
                {
                    title: "Prices From",
                    content: "USD 2.550"
                },
                {
                    title: "Area From (SQFT)",
                    content: "USD 2.550"
                },
                {
                    title: "Available Units",
                    content: "45"
                }
            ],
            link: {
                title: "Register Interest",
                url: "#",
                external: false
            }
        }
    },
    {
        name: "Box (Bottom Left)",
        module_name: "BoxBottomLeftComponent",
        component_name: "BoxBottomLeftComponent",
        thumbnail: `${thumbnailPath}BoxLeftComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Rental Program",
            description:
                "Unwind and relax in the pristine coastline of Trašte Bay – Book your holiday apartment now and spend your vacation in a dynamic and breathtaking resort, Luštica Bay.",
            cardNumber: "01.",
            link: {
                title: "Discover",
                url: "#",
                external: false
            },
            background: null,
            logo: null
        }
    },
    {
        name: "Banner Scroll",
        module_name: "BannerScrollComponent",
        component_name: "BannerScrollComponent",
        thumbnail: `${thumbnailPath}HeroVideoSliderComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "0px",
                    bottom: "0px"
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "things to do",
            background: null
        }
    },
    {
        name: "Three Cards Slider",
        module_name: "ThreeCardsSliderComponent",
        component_name: "ThreeCardsSliderComponent",
        thumbnail: `${thumbnailPath}CarouselComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            slider: [
                {
                    title: "WORLD RETAIL AWARD 2019",
                    details: "nihil opus est exemplis",
                    image: null
                },
                {
                    title: "WORLD RETAIL AWARD 2019",
                    details: "nihil opus est exemplis",
                    image: null
                },
                {
                    title: "WORLD RETAIL AWARD 2019",
                    details: "nihil opus est exemplis",
                    image: null
                }
            ]
        }
    },
    {
        name: 'Slider Card',
        module_name: 'SliderCardComponent',
        component_name: 'SliderCardComponent',
        thumbnail: `${thumbnailPath}SliderCardComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            bgColor: 'cream',
            title: "what they said",
            gallery: [
                {
                    bigNumber: "01",
                    image: null,
                    title: 'jacob budiman',
                    subtitle: 'ceo of lhm',
                    description: 'Nam illud vehementer repugnat, eundem beatum esse et multis malis oppressum. Verum hoc idem saepe faciamus. Sic, et quidem diligentius saepiusque ista loquemur inter nos agemusque communiter.'
                }
            ]
        }
    },
    {
        name: "Contact Form",
        module_name: "ContactFormComponent",
        component_name: "ContactFormComponent",
        thumbnail: `${thumbnailPath}ContactFormComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            },
            customFields:[
                {
                    title:"Form after submit link",
                    model:'after_submit_link',
                    component:'Input'
                },
                {
                    title:"Default Selected Interest",
                    model:'defaultSelectedInterest',
                    component:'Input'
                },

            ]
        },
    },
    {
        name: "Marina Enquiry Form",
        module_name: "MarinaFormComponent",
        component_name: "MarinaFormComponent",
        thumbnail: `${thumbnailPath}MarinaFormComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            },
            customFields:[
                {
                    title:"Form after submit link",
                    model:'after_submit_link',
                    component:'Input'
                }
            ]
        },
        model: {
            title: 'Marina Enquiry',
        }
    },
    {
        name: "Berth Marina Form",
        module_name: "BerthMarinaFormComponent",
        component_name: "BerthMarinaFormComponent",
        thumbnail: `${thumbnailPath}BerthMarinaFormComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            },
            customFields:[
                {
                    title:"Form after submit link",
                    model:'after_submit_link',
                    component:'Input'
                }
            ]
        },
        model: {
            title: 'Berth Marina Enquiry',
        }
    },
    {
        name: "Event Post",
        module_name: "EventPostComponent",
        component_name: "EventPostComponent",
        thumbnail: `${thumbnailPath}EventPostComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
    },
    {
        name: "Slider Progress",
        module_name: "SliderProgressComponent",
        component_name: "SliderProgressComponent",
        thumbnail: `${thumbnailPath}SliderProgress.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: "Apartment & Service Standards",
            sliders: [
                {
                    title: "Modern Furnishings",
                    description:
                        "Nam illud quidem adduci vix possum, ut ea, quae senserit ille. Piso, tibi etiam atque. Nam illud quidem adduci vix possum, ut ea, quae senserit ille.",
                    image: null
                }
            ]
        }
    },
    {
        name: "Image Slider",
        module_name: "ImageSliderComponent",
        component_name: "ImageSliderComponent",
        thumbnail: `${thumbnailPath}HeroVideoSliderComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            slider: [
                {
                    image: null
                },
                {
                    image: null
                },
                {
                    image: null
                }
            ]
        }
    },
    {
        name: 'Map Box',
        module_name: 'MapBoxComponent',
        component_name: 'MapBoxComponent',
        thumbnail: `${thumbnailPath}MapBoxComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            background: null,
            locations: [
                {
                    x: 0,
                    y: 0,
                    categories: [
                        'Centrale', 'Lainch Date August'
                    ],
                    title: 'Outlet Village',
                    description: 'Nam illud quidem adduci vix possum, ut ea, quae senserit ille, tibi non vera ' +
                        'videantur.',
                    link: {
                        title: 'Discover More',
                        url: '#',
                        external: false
                    }
                }
            ]
        },
    },
    {
        name: 'Box Right Tab',
        module_name: 'BoxRightTabComponent',
        component_name: 'BoxRightTabComponent',
        thumbnail: `${thumbnailPath}BoxRightTabComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            images: [
                {
                    tab: "Studio",
                    image: null,
                    title: "Floorplan",
                    info: [
                        {
                            title: "Configuration Type",
                            value: "Studio - 1 Bath"
                        },
                        {
                            title: "Total Area",
                            value: "35 SQM"
                        }
                    ],
                    link: {
                        title: "Download",
                        url: "#",
                        external: false
                    }
                }
            ]
        }
    },
    {
        name: 'Location Box',
        module_name: 'LocationBoxComponent',
        component_name: 'LocationBoxComponent',
        thumbnail: `${thumbnailPath}LocationBoxComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: 'Find Our Location',
            description: 'Nam illud quidem adduci vix possum, ut ea, quae senserit ille, tibi non vera videantur. ' +
                'Atqui iste locus est.',
            columns: [
                {
                    title: 'Lustica Development Ad',
                    description: '',
                },
                {
                    title: 'Phone',
                    description: '',
                },
                {
                    title: 'General Inquiry',
                    description: '',
                },
                {
                    title: 'Real Estate Enquiry',
                    description: '',
                },
                {
                    title: 'Marina Enquiry',
                    description: '',
                },
                {
                    title: 'Rental Enquiry',
                    description: '',
                },
            ]
        }
    },
    {
        name: 'Map',
        module_name: 'MapComponent',
        component_name: 'MapComponent',
        thumbnail: `${thumbnailPath}MapComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            description: 'Nam illud quidem adduci vix possum, ut ea, quae senserit ille,' +
                ' tibi non vera videantur. Atqui iste locus est, Piso, tibi etiam atque' +
                ' etiam confirmandus, inquam.'
        }
    },
    {
        name: 'Blog List',
        module_name: 'BlogListComponent',
        component_name: 'BlogListComponent',
        thumbnail: `${thumbnailPath}BlogListComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {}
    },
    {
        name: 'Article',
        module_name: 'ArticleComponent',
        component_name: 'ArticleComponent',
        thumbnail: `${thumbnailPath}ArticleComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: 'Lorem Ipsum Dolor Sit Amet',
            content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>'
        }
    },
    {
        name: 'Job List',
        module_name: 'JobListComponent',
        component_name: 'JobListComponent',
        thumbnail: `${thumbnailPath}JobListComponent.jpg`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
        model: {
            title: 'Job Openings'
        }
    },
    {
        name: 'Elfsight',
        module_name: 'ElfsightComponent',
        component_name: 'ElfsightComponent',
        thumbnail: `${thumbnailPath}ElfsightComponent.png`,
        options: {
            style: {
                margin: {
                    top: "80px",
                    bottom: "80px"
                },
                margin_mobile: {
                    top: "80px",
                    bottom: "80px",
                },
                overlay: "0.3"
            }
        },
    }
]
