//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex'
import {VueSelect} from 'vue-select'
import countries from '~/assets/data/country.json'
import TreeSelect from '@riophae/vue-treeselect'
import {differenceWith, isEqual, find} from 'lodash'

export default {
    name: "VSelectOptions",
    components: {VSelect: VueSelect, TreeSelect},
    props: {
        wvalue: {
            required: false,
            type: String,
        },
        opts: {
            required: false,
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: true
        },
        value: {
            type: String,
            required: false
        },
        unitSize: {
            type: Array,
            required: false
        },
        defaultInterestValue:{
            type: String,
            required:false
        }
    },
    data() {
        return {
            selected: null,
            countries,
            opened: false,
            op: false,
            momod: [1],
            branch: null,
            langu: null,
            options: [
                {
                    id: 1,
                    label: 'General Information',
                    checked: true
                },
                {
                    id: 2,
                    label: 'Real Estate',
                    children: [
                        {
                            id: 're-1',
                            label: 'Marina Village Apartments',
                            checked: false
                        },
                        {
                            id: 're-2',
                            label: 'Marina Village Townhouses and Villas',
                            checked: false
                        },
                        {
                            id: 're-3',
                            label: 'Golf Apartments',
                            checked: false
                        },
                        {
                            id: 're-4',
                            label: 'Golf Townhouses and Villas',
                            checked: false
                        },
                        {
                            id: 're-5',
                            label: 'Centrale Apartments',
                            checked: false
                        },
                        {
                            id: 're-6',
                            label: 'Hotel Condos',
                            checked: false
                        },
                        {
                            id: 're-7',
                            label: 'CIP - Passport Programme',
                            checked: false
                        }
                    ],
                    checked: false
                }
            ],
            woptions: [
                { name: 'All Size', id: 1 },
                { name: '0BDR', id: 2 },
                { name: '1BDR', id: 3 },
                { name: '2BDR', id: 4 },
                { name: '3BDR', id: 5 },
                { name: '4BDR', id: 6 },
                { name: '5BDR', id: 7 },
            ],
            uniting: false,
        }
    },
    computed: {
        ...mapState({
            smoothScroll: state => state.SmoothScroll.smoothScroll,
        })
    },
    mounted(){
        if(this.defaultInterestValue){
            this.momod = this.defaultInterestValue.split(',')
        }
    },
    watch: {
        momod(val) {
            this.$emit('input', document.querySelector('.vue-treeselect__multi-value').innerText)
            this.uniting = val.includes(2)

            if (val.length !== 2) {
                this.$emit('winput', null)
            }
        },
        langu() {
            this.$emit('input', this.langu)
        },
        opened(val) {
            if (val) {
                this.smoothScroll.stop()
            } else {
                this.smoothScroll.start()
            }
        }
    },
    methods: {
        find,
        onTreeChange(val) {
            let newVal = differenceWith(val, this.momod, isEqual)
            console.log(newVal)

            if (newVal.includes(1)) {
                this.branch = 1

                this.momod = val.filter((item) => {
                    return item === 1
                })
            } else if (val.includes(2)) {
                this.branch = 2

                this.momod = val.filter((item) => {
                    return item === 2 || (typeof item === 'string' && item.startsWith('re-'))
                })
            }

            if (newVal.includes(3)) {
                this.branch = 3

                this.momod = val.filter((item) => {
                    return item === 3 || (typeof item === 'string' && item.startsWith('rental-'))
                })
            }

            if (newVal.includes('rental-1')) {
                this.momod = val.filter((item) => item === 3 || item === 'rental-1')
            } else if (newVal.includes('rental-2')) {
                this.momod = val.filter((item) => item === 3 || item === 'rental-2')
            }

            if (newVal.includes(4)) {
                this.branch = 4

                this.momod = val.filter((item) => {
                    return item === 4
                })
            }

            if (val.length === 0) {
                this.momod = []
            }
        }
    },
}
