//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import {VInput, VEmail, VSelectOptions, VTextarea} from "@/components/partials/inputs"
import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate'
import axios from "axios"

import en from 'vee-validate/dist/locale/en.json'
import mne from 'vee-validate/dist/locale/sr_Latin.json'
import ru from 'vee-validate/dist/locale/ru.json'


export default {
    name: "ContactFormComponent",
    components: {VInput, VEmail, VSelectOptions, VTextarea, ValidationProvider, ValidationObserver},
    mixins: [GlobalMixin],
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        unitSize: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            wunit: null,
            model: {},
            form: [],
            interest: null,
            privacy: null,
            isLoading: false,
            integrationSource: null,
            integrationMedium: null,
            errored: false,
        }
    },
    computed: {
        readPrivacyText() {
            return this.escapedStaticText('form - read privacy policy')
        },
        collectText() {
            const text = this.getStaticText('form - collect')

            if (text != null) {
                return text.replace(/<(\/|)p>/g, '').replace(/<b>(.*?)<\/b>/,
                    `<a class="btn collect__link" href="${this.renderLink(this.getCurrentLanguage + '/privacy-policy')}" target="_blank" rel="noopener noreferrer">$1</a>`)
            }

            return null
        },
        sendText() {
            const text = this.getStaticText('command - send')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    watch: {
        model: {
            deep: true,
            handler(val) {
                console.log(val)
            }
        }
    },
    created() {
        const messages = {en, mne, ru}

        if (Object.hasOwnProperty.call(messages, this.getCurrentLanguage)) {
            localize(this.getCurrentLanguage, messages[this.getCurrentLanguage])
        }
    },
    mounted() {
        let form = this.getFormBuilder('contact')

        for (let input of form.inputs) {
            this.model[input.options.input_model_name] = input.options.input_default_value
        }

        this.form = form

        if (window.rules == null) {
            window.rules = require('vee-validate/dist/rules')
        }

        extend('required', window.rules.required)
        extend('email', window.rules.email)
        extend('numeric', window.rules.numeric)
        extend('nosymbol', this.nosymbol)
    },
    methods: {
        async onSubmit() {
            this.errored = false

            if (this.isLoading) return;
            this.isLoading = true;

            let error = false;

            this.$emit('submitStart');
            let data = []

            for (let input of this.form.inputs) {
                data.push({
                    key: input.options.input_label,
                    value: this.model[input.options.input_model_name]
                })
            }

            try {
                if (window.location.hostname.match(/lusticabay\.com$/) != null) {
                    await axios.post('https://prod-67.westeurope.logic.azure.com:443/workflows/8d6132c7bbad46faaf1af8737d4f1b4c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=9Nc8ZR-qR49hHYmERv1H31QrNzVicuIqR5DymAid5n0',
                        {
                            Lead: {
                                firstname: this.model['first name'],
                                lastname: this.model['last name'],
                                email: this.model.email,
                                fullmobilenumber: this.$refs.phone[0].phoneCode != null ? this.$refs.phone[0].phoneCode.dial_code + this.model.phone : null,
                                message: this.model.message,
                                interest: this.$refs.interest[0].branch,
                                preferredlanguage: this.$refs.preferredlanguage[0].langu,
                                MarinaVillageApartments: this.$refs.interest[0].momod.includes('re-1') ? '1' : '0',
                                MarinaVillageTownhousesandVillas: this.$refs.interest[0].momod.includes('re-2') ? '1' : '0',
                                GolfApartments: this.$refs.interest[0].momod.includes('re-3') ? '1' : '0',
                                GolfTownhousesandVillas: this.$refs.interest[0].momod.includes('re-4') ? '1' : '0',
                                CentraleApartments: this.$refs.interest[0].momod.includes('re-5') ? '1' : '0',
                                HotelCondos: this.$refs.interest[0].momod.includes('re-6') ? '1' : '0',
                                CIPPassportProgramme: this.$refs.interest[0].momod.includes('re-7') ? '1' : '0',
                                country: parseInt(this.model.Country),
                                rentalOption: this.$refs.interest[0].momod.includes('rental-1') ? 1 : this.$refs.interest[0].momod.includes('rental-2') ? 2 : null,
                                unitsize: this.wunit,
                                // integrationSource: this.integrationSource,
                                // integrationMedium: this.integrationMedium
                            }
                        });
                } else {
                    await axios.post('https://prod-130.westeurope.logic.azure.com:443/workflows/74e9b109d81d460897ba2154d619ad85/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=aIbo_NmvZllXbKJEme-x6e0A_YqXpGyhsPe4k9jTiRM',
                        {
                            Lead: {
                                firstname: this.model['first name'],
                                lastname: this.model['last name'],
                                email: this.model.email,
                                fullmobilenumber: this.$refs.phone[0].phoneCode != null ? this.$refs.phone[0].phoneCode.dial_code + this.model.phone : null,
                                message: this.model.message,
                                interest: this.$refs.interest[0].branch,
                                preferredlanguage: this.$refs.preferredlanguage[0].langu,
                                MarinaVillageApartments: this.$refs.interest[0].momod.includes('re-1') ? '1' : '0',
                                MarinaVillageTownhousesandVillas: this.$refs.interest[0].momod.includes('re-2') ? '1' : '0',
                                GolfApartments: this.$refs.interest[0].momod.includes('re-3') ? '1' : '0',
                                GolfTownhousesandVillas: this.$refs.interest[0].momod.includes('re-4') ? '1' : '0',
                                CentraleApartments: this.$refs.interest[0].momod.includes('re-5') ? '1' : '0',
                                HotelCondos: this.$refs.interest[0].momod.includes('re-6') ? '1' : '0',
                                CIPPassportProgramme: this.$refs.interest[0].momod.includes('re-7') ? '1' : '0',
                                country: parseInt(this.model.Country),
                                rentalOption: this.$refs.interest[0].momod.includes('rental-1') ? 1 : this.$refs.interest[0].momod.includes('rental-2') ? 2 : null,
                                unitsize: this.wunit,
                                // integrationSource: this.integrationSource,
                                // integrationMedium: this.integrationMedium
                            }
                        });
                }
            } catch (e) {
                console.log(e);
                error = true;

                if (Object.hasOwnProperty.call(e.response, 'data')) {
                    if (e.response.data.message.startsWith('A Lead with the same Primary Email')) {
                        this.$root.$emit('hasDuplicate', 'email');
                        this.errored = true
                    }

                    if (e.response.data.message.startsWith('A Lead with the same primary Phone')) {
                        this.$root.$emit('hasDuplicate', 'phone');
                        this.errored = true
                    }
                }

                setTimeout(() => {
                    const el = document.querySelector('.enquiry__container')
                    el.scrollTop = el.scrollHeight
                }, 500)
            } finally {
                this.$emit('submitEnd');
                this.isLoading = false;
            }

            if (!error) {
                if (this.$route.params.slug === 'thank-you') {
                    window.location.reload();
                }

                if (this.getOptions?.customFieldValues?.after_submit_link) {
                    await this.$router.push(this.getOptions.customFieldValues.after_submit_link)
                } else {
                    if (this.getCurrentLanguage === 'mne') {
                        await this.$router.push('/' + this.getCurrentLanguage + '/hvala')
                    } else if (this.getCurrentLanguage === 'ru') {
                        await this.$router.push('/' + this.getCurrentLanguage + '/spasibo')
                    } else {
                        await this.$router.push('/' + this.getCurrentLanguage + '/thank-you')
                    }
                }
            }
        }
    },
}
