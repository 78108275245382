import Vue from 'vue'
import {isEmptyObject} from '../functions/helpers'
import {CrudHelper} from '../functions/Crudhelper'
import _ from 'lodash'
import ENV from '../../environment/ENV'
import {COMPONENTS} from '../../components/tpl/template'


class SettingConfig {
    constructor(el, context, bind) {
        this._el = el
        this._context = context
        this._binding = bind
    }

    configBackgroundImage(options) {
        const {background} = this._binding
        if (background) {
            const container = this._el.querySelector(background.image.selector)
            const imageConfig = _.get(options.config, 'section_background_image_config')
            if (imageConfig && _.isObject(imageConfig)) {
                const size = _.get(options.config, 'section_background_image_config.size')
                const position = _.get(options.config, 'section_background_image_config.position')
                const repeat = _.get(options.config, 'section_background_image_config.repeat')

                if (size) container.style.backgroundSize = size
                if (position) container.style.backgroundPosition = position

                if (repeat) container.style.backgroundRepeat = 'repeat'
                else container.style.backgroundRepeat = 'no-repeat'
            }

            this.renderBackgroundImage(options, background.image.selector)

            return imageConfig
        }

        return null
    }


    renderBackgroundImage(options, selector) {
        const file = _.get(options.config, 'section_background_image')

        if (file) {

            let filePath = `${ENV().asset_url}/`

            const container = this._el.querySelector(selector)

            /**
             * If AWS set, get path form aws server
             */
            if (file.is_aws) filePath = `${file.aws_file_url}/`

            filePath = `${filePath}${file.path}/${file.filename.raw}`

            if (container) {
                container.style.backgroundImage = `url(${filePath})`
            }

        }
    }


    renderBackgroundColor(options) {
        const {background} = this._binding

        if (background && background.color) {
            const color = _.get(options.config, 'section_background_color')
            if (options && options.config && color) {
                const {selectors} = background.color
                if (!selectors) {
                    this._el.style.backgroundColor = color
                } else {
                    const elems = this._el.querySelectorAll(selectors)

                    if (elems) {
                        elems.forEach(elem => {
                            elem.style.backgroundColor = color
                        })
                    }
                }

                return color
            }
        }


        return null
    }
}

const initEditor = async (el, binding, vnode) => {
    let {options, state, listing, onListingResults, background,module_name} = binding.value
    const {context} = vnode
    const {$root} = context
    const isUserCanEdit = context.$store.getters.getCurrentUserCanEditContent
    let isFormAdded = false

    await getListing(options, onListingResults,$root)


    const setting = new SettingConfig(el, context, binding.value)

    const bgSetting = setting.configBackgroundImage(options)
    const bgColor = setting.renderBackgroundColor(options)


    if (isUserCanEdit) {


        if (!options) options = {}

        if (!options.style) options.style = {
            margin: {},
            margin_mobile: {}
        }

        if (!options.style.margin_mobile) options.style.margin_mobile = {}


        if (!options.config) options.config = {}

        if(!options.customFields) options.customFields = []

        if(!options.customFieldValues) options.customFieldValues = {}

        const button = document.createElement('div')
        el.classList.add('section__has-button-editor')
        button.classList.add('button-editor__section')
        button.innerHTML = 'Setting'
        el.appendChild(button)
        el.style.marginTop = options.style.margin.top
        el.style.marginBottom = options.style.margin.bottom

        renderOverlayOpacity(el, options.style.overlay)

        const buttonBound = button.getBoundingClientRect()

        if (buttonBound) {
            if (buttonBound.y === 0 || buttonBound.y < 0) {
                button.classList.add('button__position-inside')
            }
        }

        button.addEventListener('click', () => {
            /**
             * Inject form into root document
             * @returns {{component: (Promise<*>|*)}}
             */


            //setup and merge customFields
            if(module_name){
                const defaultComp = COMPONENTS.find(comp => comp.module_name === module_name)
                let customFields = defaultComp.options?.customFields
                if(customFields){
                    options.customFields = customFields
                }
            }

            const asyncComponent = () => ({
                component: import('../component-editor/MarginEditor/MarginEditor')
            })

            asyncComponent().component.then(async comp => {
                
                if (listing) {
                    await getPostListing(options)
                }

                console.log('options',options)

                const newComp = Vue.extend(comp.default)
                const form = new newComp()

                form.margin = options.style.margin
                form.margin_mobile = options.style.margin_mobile
                form.config = options.config
                form.hasListing = listing
                form.postListing = options.postListing
                form.overlay = options.style.overlay
                form.customFields = options.customFields
                form.customFieldValues = options.customFieldValues

                /*
                * Has overlay
                * */
                renderOverlayOpacity(el, options.style.overlay)

                if (background && _.isObject(background)) {
                    if (background.color) {
                        form.hasPicker = true
                    }

                    if (background.image) {
                        form.hasBackgroundImage = true
                    }
                }


                if (bgSetting) form.showBackgroundImageSetting = true


                form.$mount()

                form.$on('onUpdate', () => {
                    el.style.marginTop = options.style.margin.top
                    el.style.marginBottom = options.style.margin.bottom

                    /**
                       Update overlay opacity
                     */

                    options.style.overlay = form.overlay
                    renderOverlayOpacity(el, options.style.overlay)

                    /**
                     * Update background color
                     */

                    setting.renderBackgroundColor(options)


                    /**
                     * Update background image config
                     */
                    if (form.hasBackgroundImage) {
                        setting.configBackgroundImage(options)
                    }


                    if (state) state(options)
                })


                form.$on('onClose', () => {
                    form.$el.parentNode.removeChild(form.$el)
                    isFormAdded = false
                })


                /**
                 * Listen background image come form file manager
                 */
                if (form.hasBackgroundImage) {
                    $root.$on('PM', event => {

                        if (!event) return
                        const {message} = event
                        if (event.key === 'SELECTED_FILE') {

                            if (message.to === form.fileManagerId) {
                                if (state) state(message.file)
                                options.config.section_background_image = message.file

                                if (_.isObject(background.image)) {
                                    const container = el.querySelector(background.image.selector)

                                    const file = message.file

                                    let filePath = `${ENV().asset_url}/`

                                    if (file.is_aws) {
                                        filePath = `${file.aws_file_url}/`
                                    }

                                    filePath = `${filePath}${file.path}/${file.filename.raw}`

                                    if (container) {
                                        container.style.backgroundImage = `url(${filePath})`
                                        container.style.backgroundRepeat = `no-repeat`
                                    }

                                    form.showBackgroundImageSetting = true
                                }
                            }
                        }
                    })
                }


                if (!isFormAdded) $root.$el.appendChild(form.$el)

                isFormAdded = true
            })
        })
    } else {
        if (!isEmptyObject(options)) {


            if (options.config && options.config.section_id) {
                el.setAttribute('id', options.config.section_id)
            }

            el.style.marginTop = options.style.margin.top
            el.style.marginBottom = options.style.margin.bottom

            renderOverlayOpacity(el, options.style.overlay)


            /**
             * Replace with mobile margin
             */
            if (!isEmptyObject(options.style.margin_mobile)) {
                if (window.innerWidth < 600) {
                    el.style.marginTop = options.style.margin_mobile.top
                    el.style.marginBottom = options.style.margin_mobile.bottom
                }
            }
        }
    }
}


const getListing = async (options, onListingResults,root) => {
    if (options && options.config) {
        if (options.config.listing && options.config.listing.key) {

            let post = null
            let model = null
            let params = {
                where: {}
            }
            //if post
            if (options.config.listing.type === 'post') {
                model = 'post'
                params.where.type = options.config.listing.key
            }
            //if single API
            else {
                model = options.config.listing.key
            }


            //if category exist
            if (options.config.listing_category && options.config.listing_category.length > 0) {
                const categories = options.config.listing_category
                let categoriesId = []
                categories.forEach((category) => {
                    categoriesId.push(category.id)
                })
                params.where.categories = {
                    $all: categoriesId
                }
            }

            //if use query
            if (options.config.listing_query) {

                const query = JSON.parse(options.config.listing_query)
                params.where = {...params.where, ...query}

            }
            //if use sort
            if (options.config.listing_sort) {
                params.sort = options.config.listing_sort
            }

            //if use limit
            if (options.config.listing_limit) {
                params.size = options.config.listing_limit
            }


            const listing = await CrudHelper.GET(model, params)

            if (listing) {
                
                if (onListingResults){
                    if(typeof onListingResults === 'string'){
                        root.$emit(onListingResults,listing.data.results)
                    }else{
                        onListingResults(listing.data.results)
                    }
                    
                } 
            }

        }
    }

    return null
}

const getPostListing = async (options) => {
    const listing = await CrudHelper.GET('post/listing/all')
    if (listing) options.postListing = listing.data

}

Vue.directive('section-config', {
    bind: initEditor
})


const renderOverlayOpacity = (el, opacity) =>  {
    const overlayElement = el.querySelector('.background-overlay')

    if(overlayElement) {
        overlayElement.style.opacity = opacity
    }
}
