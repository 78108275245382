import dayjs from 'dayjs'

import 'dayjs/locale/en'

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

dayjs.locale('en')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
