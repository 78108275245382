import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4af4ac70&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=4af4ac70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af4ac70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LightboxComponent: require('/var/www/frontend-lustica-nuxt/components/global/LightboxComponent/LightboxComponent.vue').default,EnquiryComponent: require('/var/www/frontend-lustica-nuxt/components/partials/EnquiryComponent/EnquiryComponent.vue').default,LoadingComponent: require('/var/www/frontend-lustica-nuxt/components/global/LoadingComponent/LoadingComponent.vue').default,HeaderComponent: require('/var/www/frontend-lustica-nuxt/components/global/HeaderComponent/HeaderComponent.vue').default,LanguageSelector: require('/var/www/frontend-lustica-nuxt/components/global/LanguageSelector/LanguageSelector.vue').default,MenuComponent: require('/var/www/frontend-lustica-nuxt/components/global/MenuComponent/MenuComponent.vue').default,SearchComponent: require('/var/www/frontend-lustica-nuxt/components/global/SearchComponent/SearchComponent.vue').default,PopupComponent: require('/var/www/frontend-lustica-nuxt/components/global/PopupComponent/PopupComponent.vue').default})
