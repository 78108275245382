export const PageStore = {
    state: {
        currentPageData: null,
        onLoad: false
    },

    mutations: {
        setCurrentPageData: (state, payload) => {
            state.currentPageData = payload
        },
        setPageLoad: (state, payload) => {
            state.onLoad = payload
        }
    },


    getters: {
        getCurrentPageData: state => state.currentPageData,
        getCurrentPageLoad: state => state.onLoad
    }
}
