import { GlobalMixin } from "../GlobalMixin";
import { mapState } from 'vuex'

export const LanguageMixin = {

    mixins: [GlobalMixin],

    data: function () {
        return {
            languages: []
        }
    },

    computed: mapState({
        lang: state => state.websiteContent.currentLanguage,
        fallbackLang: state => state.websiteContent.defaultLanguage
    }),

    methods: {
        getLanguages() {
            return this.getWebConfig.language
        },

        getContentLanguage(content, language) {
            if (typeof content !== 'object') return
            return content[language] ? content[language] : content['en'];
        },

        t(content) {
            return this.getContentLanguage(content, this.lang)
        },

        translateRoute(language) {

            let slug = null

            /***
             * in details page if field for customUrl is not defined
             * the value will remain null
             */
            
            if (this.getPageData.customUrl != null) {
                slug = this.getPageData.single.customUrl[language]
            } else {
                slug = this.getPageData.single.slug[language]
            }

            /*
             * if neither custom url and default slug defined use fallback language
             * could trigger error if the page is not defined in fallback language
             * further testing needed
             */
            
            if (slug == null) {
                slug = this.getPageData.single.slug[this.fallbackLang]
            }

            if (this.$route.path === '/') {
                return '/' + language
            }

            if (slug === 'homepage') {
                return '/' + language
            }

            let result = this.$router.resolve({
                name: this.$route.name,
                params: {
                    ...this.$route.params,
                    language,
                    slug
                }
            });
            
            return result.href;
        }
    }
}
